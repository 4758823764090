import React from 'react';
import { ErrorDisplay, TextInput } from './text';
import { useId } from 'react-id-generator';

interface DateOfBirthInputProps {
  onChange: (day: string, month: string, year: string, dateObject: Date) => void;
  initialValue?: [string, string, string];
  error?: string | null | false;
}

export const DateOfBirthInput: React.FunctionComponent<
  React.PropsWithChildren<DateOfBirthInputProps>
> = (props) => {
  const dayRef = React.useRef<HTMLInputElement>(null);
  const monthRef = React.useRef<HTMLInputElement>(null);
  const yearRef = React.useRef<HTMLInputElement>(null);

  const nextRefMap = { month: dayRef, day: yearRef };
  const prevRefMap = { day: monthRef, year: dayRef };

  const [formData, setFormData] = React.useState({
    day: props.initialValue?.[0] || '',
    month: props.initialValue?.[1] || '',
    year: props.initialValue?.[2] || '',
    fieldToFocus: ''
  });

  React.useEffect(() => {
    if (formData.fieldToFocus) {
      nextRefMap[formData.fieldToFocus]?.current?.focus();
      setFormData({ ...formData, fieldToFocus: '' });
    }
  }, [formData]);

  const setData = (field: string, value: string) => {
    setFormData((prev) => {
      // We need to internally validate the value and do proper focus handling.
      // Right now we don't care about transferring excess values to the next input,
      // for example, user clicks on month and input '128', we'll only focus on the year field and
      // sanitize to '12' and we'll drop the '8' input.
      let sanitizedValue = value;
      switch (field) {
        case 'day':
          sanitizedValue = value.substring(0, 2);
          break;
        case 'month':
          sanitizedValue = value.substring(0, 2);
          break;
        case 'year':
          sanitizedValue = value.substring(0, 4);
          break;
      }

      // Update state and focus
      const newFormData = {
        ...prev,
        [field]: sanitizedValue,
        fieldToFocus: value.length > prev[field].length && sanitizedValue.length >= 2 ? field : ''
      };

      // Adds date object representation for the picker
      const dateObject = new Date(
        Date.UTC(
          Number(newFormData.year),
          Number(newFormData.month) - 1,
          Number(newFormData.day),
          0,
          0,
          0,
          0
        )
      );
      props.onChange(newFormData.day, newFormData.month, newFormData.year, dateObject);
      return newFormData;
    });
  };

  // Handles keyboard press, here we do backspace handling and also limit input to only numeric
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, field: string) => {
    if (!/[0-9]|Backspace|Tab|Delete|Arrow[Left|Right|Up|Down]/.test(e.key)) {
      e.preventDefault();
    }

    if (e.key === 'Backspace' || e.key === 'Delete') {
      if (formData[field] === '') {
        prevRefMap[field]?.current?.focus();
      }
    }
  };

  // Prepend leading 0s for single digit date and month
  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>, field: string) => {
    const inputValue = e.target.value;
    if (field !== 'year' && inputValue.length === 1) {
      setData(field, ('00' + inputValue).slice(-2));
    }
  };

  const [mmId, ddId, yyyyId] = useId(3);

  return (
    <div>
      <div style={{ display: 'flex', gap: '8px' }}>
        <label style={{ display: 'none' }} htmlFor={mmId}>
          Month
        </label>
        <TextInput
          id={mmId}
          style={{ maxWidth: '64px' }}
          placeholder="MM"
          inputMode="numeric"
          autoComplete="bday-month"
          error={!!props.error}
          value={formData.month}
          onChange={(e) => setData('month', e.currentTarget.value)}
          onKeyDown={(e) => handleKeyDown(e, 'month')}
          onBlur={(e) => handleBlur(e, 'month')}
          ref={monthRef}
        />
        <label style={{ display: 'none' }} htmlFor={ddId}>
          Day
        </label>
        <TextInput
          id={ddId}
          style={{ maxWidth: '64px' }}
          placeholder="DD"
          inputMode="numeric"
          autoComplete="bday-day"
          error={!!props.error}
          value={formData.day}
          onChange={(e) => setData('day', e.currentTarget.value)}
          onKeyDown={(e) => handleKeyDown(e, 'day')}
          onBlur={(e) => handleBlur(e, 'day')}
          ref={dayRef}
        />
        <label style={{ display: 'none' }} htmlFor={yyyyId}>
          Year
        </label>
        <TextInput
          id={yyyyId}
          style={{ maxWidth: '108px' }}
          placeholder="YYYY"
          inputMode="numeric"
          autoComplete="bday-year"
          error={!!props.error}
          value={formData.year}
          onChange={(e) => setData('year', e.currentTarget.value)}
          onKeyDown={(e) => handleKeyDown(e, 'year')}
          onBlur={(e) => handleBlur(e, 'year')} // Only here for consistency, does nothing at the moment
          ref={yearRef}
        />
      </div>
      {props.error && <ErrorDisplay>{props.error}</ErrorDisplay>}
    </div>
  );
};
