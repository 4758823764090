import * as React from 'react';
import { sdkCompletePolicy } from '@oysterjs/core/api/sdk';

import { OysterLogo } from '@oysterjs/ui/Logo';
import { PageContainer, PageSection } from '@oysterjs/ui/Page';
import { Spinner } from '@oysterjs/ui/Spinner';
import apm from '@oysterjs/core/apm';
import { Policy, ReferralChannelType } from '@oysterjs/types';
import { useAnalytics } from '@oysterjs/core/analytics/analytics';
import { GetOysterEventNames } from '@oysterjs/core/analytics/googletags';
import { WrappedError, ErrorType, ErrorCode } from '@oysterjs/core/errors';
import { ButtonLink } from '@oysterjs/ui/Button';
import { IoArrowForward } from 'react-icons/io5';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';

const PolicyCompletePage = (props: { policyId: string }): JSX.Element => {
  const [alreadyActivated, setAlreadyActivated] = React.useState(false);
  const [policy, setPolicy] = React.useState<Policy>();
  const [error, setError] = React.useState('');
  const [dashboardRedirect, setDashboardRedirect] = React.useState<string | undefined>();
  const [showAltText, setShowAltText] = React.useState(false);

  const analytics = useAnalytics();

  const handleActivate = async () => {
    try {
      const { Policy, Redirect } = await sdkCompletePolicy(props.policyId);
      // If user is already in the same domain, we only need to
      // push to the policy details route
      if (Redirect) {
        const redirectionUrl = new window.URL(Redirect);
        const locationUrl = new window.URL(window.location.toString());
        if (redirectionUrl.host === locationUrl.host) {
          setDashboardRedirect(`/policies/${Policy.ID}/overview`);
        } else {
          setDashboardRedirect(Redirect);
        }
      }
      setPolicy(Policy);
      setShowAltText(
        policy?.Coverage.ActivationTrigger != null &&
          policy.InsuredItems.some((i) => i.HasNotReceived)
      );

      analytics.event(GetOysterEventNames.ApplicationSubmitted, {
        policy_id: Policy.ID,
        policy_type: Policy.Type,
        referral_channel:
          Policy.ReferralChannelInfo?.ReferralChannel || ReferralChannelType.ReferralChannelDirect,
        ...Policy.ReferralChannelInfo?.Settings
      });
    } catch (e) {
      const err = WrappedError.asWrappedError(e);
      if (
        err.type() === ErrorType.preconditionFailed &&
        err.code() === ErrorCode.alreadySubmitted
      ) {
        setShowAltText(
          policy?.Coverage.ActivationTrigger != null &&
            policy.InsuredItems.some((i) => i.HasNotReceived)
        );
        setAlreadyActivated(true);
        return;
      }
      setError(err.message);
    }
  };

  React.useEffect(() => {
    handleActivate();
  }, [props.policyId]);

  React.useEffect(() => {
    apm().sendEvent('oyster.d2c.flow_complete', {
      flow_type: new URLSearchParams(window.location.search).get('flow'),
      policy_id: props.policyId
    });
  }, [props.policyId]);

  return (
    <>
      {!alreadyActivated && !policy && !error && (
        <>
          <PageSection noBorder>
            <h1>Just a moment...</h1>
            <p>
              Thank you for choosing Oyster. We're glad you're joining us! Just one moment as we
              process your information...
            </p>
          </PageSection>
          <PageSection noBorder noPadding centered>
            <Spinner color="#333333" />
          </PageSection>

          <PageSection noBorder noPadding>
            {error && <p style={{ color: '#d1344b' }}>{error}</p>}
          </PageSection>
        </>
      )}
      {(alreadyActivated || policy) && !showAltText && (
        <>
          <PageSection noBorder>
            <h1>You're all set!</h1>
            <p>
              We're processing your application. We'll notify you via email once your coverage is
              activated.
            </p>
            <p>Thank you for choosing Oyster!</p>
            <img style={{ width: '100%' }} src="/images/celebrate.svg" alt="" />
          </PageSection>
          {dashboardRedirect && (
            <PageSection noPadding style={{ paddingBottom: '40px' }}>
              <ButtonLink icon={<IoArrowForward />} primary href={dashboardRedirect}>
                Continue to Dashboard
              </ButtonLink>
            </PageSection>
          )}
        </>
      )}
      {(alreadyActivated || policy) && showAltText && (
        <>
          <PageSection noBorder>
            <h1>You're all set!</h1>
            <p>
              If you entered a start date or tracking number, we will automatically activate your
              policy for you or you can manually activate it anytime. If you selected to manually
              activate, please activate your policy on your Oyster dashboard once the item has
              arrived.
            </p>
            <p>Thank you for choosing Oyster!</p>
            <img style={{ width: '100%' }} src="/images/celebrate.svg" alt="" />
          </PageSection>
          {dashboardRedirect && (
            <PageSection noPadding style={{ paddingBottom: '40px' }}>
              <ButtonLink icon={<IoArrowForward />} primary href={dashboardRedirect}>
                Continue to Dashboard
              </ButtonLink>
            </PageSection>
          )}
        </>
      )}
      {error && (
        <>
          <PageSection noBorder>
            <h1>Oops!</h1>
            <p>
              There was an error submitting your information. Please reach out to{' '}
              <a href="mailto:support@withoyster.com">support@withoyster.com</a> and we'll help
              resolve the issue.
            </p>
          </PageSection>
          <PageSection noBorder noPadding>
            {error && <p style={{ color: '#d1344b' }}>{error}</p>}
          </PageSection>
        </>
      )}
      <PageSection>
        <div style={{ height: '40px' }} />
      </PageSection>
    </>
  );
};

export default (props: { policyId: string }): JSX.Element => (
  <PageContainer width={700}>
    <PageSection centered>
      <OysterLogo scale={1.5} inline />
    </PageSection>
    <ErrorBoundary forceMobile>
      <PolicyCompletePage {...props} />
    </ErrorBoundary>
  </PageContainer>
);
