import { useCookies, Cookies } from 'react-cookie';
import {
  PolicyReferralChannelInfo,
  ReferralChannelType,
  ReferralLinkIntegration
} from '@oysterjs/types';
import apm from '../apm';

const DEPRECATED_ATTRIBUTION_COOKIE = '_oysterc';
const ATTRIBUTION_COOKIE = 'oyster_attribution';
const TRACKING_COOKIE = 'oyster_direct';
const COOKIE_TYPE = 'type';

export enum CookieParameters {
  AttributionType = 'type',
  MerchantIntegrationId = 'merchant_integration_id',
  UtmSource = 'utm_source',
  UtmCampaign = 'utm_campaign',
  UtmMedium = 'utm_medium',
  UtmTerm = 'utm_term',
  GoogleId = 'gclid',
  FacebookId = 'fbclid',
  PageHistory = '_oh'
}

enum AttributionCookieType {
  Merchant = 'merchant',
  Marketing = 'marketing',
  Direct = 'direct'
}

const getPageHistory = (params: URLSearchParams): string[] => {
  try {
    return JSON.parse(params.get(CookieParameters.PageHistory) || '[]');
  } catch (e) {
    apm().captureError(e);
    return [];
  }
};

const getParametersString = (params: URLSearchParams): string => {
  const paramsCopy = new URLSearchParams(params);
  paramsCopy.delete(CookieParameters.PageHistory);
  return paramsCopy.toString();
};

export const isMerchantReferral = (): boolean =>
  getCookieData().has(CookieParameters.MerchantIntegrationId);

export const useReferralChannel = (): PolicyReferralChannelInfo | undefined => {
  useCookies();
  const params = getCookieData();

  // Extract params if exists
  if (params) {
    let type = params.get(COOKIE_TYPE);
    if (!type) {
      // If type doesn't exist, we try to infer based on other existing parameters
      if (params.has(CookieParameters.MerchantIntegrationId)) {
        type = AttributionCookieType.Merchant;
      } else if (
        params.has(CookieParameters.UtmSource) &&
        params.has(CookieParameters.UtmCampaign)
      ) {
        type = AttributionCookieType.Marketing;
      } else if (params.has(CookieParameters.GoogleId) || params.has(CookieParameters.FacebookId)) {
        type = AttributionCookieType.Marketing;
      }
    }

    const ReferralSubChannel = apm().getReferralSubChannel();
    switch (type) {
      case AttributionCookieType.Merchant:
        return {
          ReferralChannel: ReferralChannelType.ReferralChannelMerchant,
          ReferralSubChannel,
          Settings: {
            MerchantID: '',
            IntegrationID: params.get(CookieParameters.MerchantIntegrationId) || ''
          }
        };
      case AttributionCookieType.Marketing: {
        let source = params.get(CookieParameters.UtmSource);
        let campaign = params.get(CookieParameters.UtmCampaign);
        if (!source) {
          if (params.has(CookieParameters.GoogleId)) {
            source = 'google';
            campaign = params.get(CookieParameters.GoogleId);
          } else if (params.has(CookieParameters.FacebookId)) {
            source = 'facebook';
            campaign = params.get(CookieParameters.FacebookId);
          }
        }

        return {
          ReferralChannel: ReferralChannelType.ReferralChannelMarketingCampaign,
          ReferralSubChannel,
          Settings: {
            Source: source || '',
            Campaign: campaign || '',
            Medium: params.get(CookieParameters.UtmMedium) || '',
            Term: params.get(CookieParameters.UtmTerm) || '',
            PageHistory: getPageHistory(params)
          }
        };
      }
      default:
        return {
          ReferralChannel: ReferralChannelType.ReferralChannelDirect,
          ReferralSubChannel,
          Settings: {
            Parameters: getParametersString(params),
            PageHistory: getPageHistory(params)
          }
        };
    }
  }

  return undefined;
};

export const setMerchantAttribution = (integration: ReferralLinkIntegration) => {
  const cookies = new Cookies();
  const params = new URLSearchParams({
    [CookieParameters.AttributionType]: AttributionCookieType.Merchant,
    [CookieParameters.MerchantIntegrationId]: integration.IntegrationID
  });
  cookies.set(ATTRIBUTION_COOKIE, params.toString(), { path: '/' });
};

const getCookieData = (): URLSearchParams => {
  const cookies = new Cookies();
  return [
    cookies.get(DEPRECATED_ATTRIBUTION_COOKIE) || '',
    cookies.get(TRACKING_COOKIE) || '',
    cookies.get(ATTRIBUTION_COOKIE) || ''
  ]
    .map((v) => new URLSearchParams(v))
    .reduce((prev, curr) => {
      curr.forEach((v, k) => prev.set(k, v));
      return prev;
    }, new URLSearchParams());
};
