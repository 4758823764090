import * as React from 'react';
import { useHistory } from 'react-router';
import apm from './apm';

interface UrlData<T> {
  integrationID?: string;
  appData?: T;
}

export const useUrlData = <T,>(): UrlData<T> => {
  const history = useHistory();

  const getData = () => {
    const params = new URLSearchParams(history.location.search);

    try {
      const data = JSON.parse(
        window.atob(decodeURIComponent(params.get('d') || encodeURIComponent(window.btoa('{}'))))
      );
      const newData = {
        integrationID: data.I,
        appData: data.D
      };

      return newData;
    } catch (err) {
      apm().captureError(err);
    }
  };

  const [data, setData] = React.useState<UrlData<T>>(getData() || {});
  React.useEffect(() => setData(getData() || {}), [history.location]);

  return data;
};

export const createUrlDataComponent = <T,>(d: UrlData<T>): string => {
  return (
    'd=' +
    encodeURIComponent(
      window.btoa(
        JSON.stringify({
          I: d.integrationID,
          D: d.appData
        })
      )
    )
  );
};
