import * as React from 'react';

import { PageSection } from '@oysterjs/ui/Page';
import { MotorProduct, Product, ProductType, ValidationError } from '@oysterjs/types';
import { TextInput } from '@oysterjs/ui/Form/text';
import { Button } from '@oysterjs/ui/Button';
import { IoArrowForward } from 'react-icons/io5';
import { FormColumn, FormContainer, FormRow, FormRowHeader } from '@oysterjs/ui/Form/builder';
import {
  RequirementCheckboxContainer,
  RequirementContentContainer,
  RequirementDescription,
  RequirementItemContainer,
  RequirementTitle
} from '@oysterjs/ui/common';
import { Checkbox } from '@oysterjs/ui/Form/checkbox';
import { Select } from '@oysterjs/ui/Form/select';

export interface MotorFormData {
  ProductType: ProductType;

  SKU?: string;
  Name?: string;
  Description?: string;
  ImageURL?: string;

  Price: string;

  ZipCode?: string;
  AccessoryValue?: string;
  Details: MotorProduct;
}

export const CollectMotorInfo: React.FunctionComponent<{
  loading?: boolean;
  validationError?: ValidationError;
  formData: MotorFormData;
  setData: (field: string, fn: (data: MotorFormData) => MotorFormData) => void;
  onContinue: (product: Product) => void;
}> = (props) => {
  const [validationError, setValidationError] = React.useState<ValidationError>();

  React.useEffect(() => {
    setValidationError(props.validationError);
  }, [JSON.stringify(props.validationError)]);

  const setData = (field: string, fn: (data: MotorFormData) => MotorFormData) => {
    if (validationError?.Field === field) {
      setValidationError(undefined);
    }
    props.setData(field, fn);
  };

  const validate = (formData: MotorFormData) => {
    if (!/^20\d\d$/.test(formData.Details.ModelYear)) {
      return {
        Field: 'ModelYear',
        Message: 'Please enter a valid model year.'
      };
    }
    if (!formData.Details.Make) {
      return {
        Field: 'Make',
        Message: 'Please enter a manufacturer.'
      };
    }
    if (!formData.Details.Model) {
      return {
        Field: 'Model',
        Message: 'Please enter a model.'
      };
    }
    if (!formData.Details.Type) {
      return {
        Field: 'Type',
        Message: 'Please select an ATV type.'
      };
    }
    if (isNaN(parseFloat(formData.Details.CCSize))) {
      return {
        Field: 'CCSize',
        Message: 'Please enter a valid engine CC.'
      };
    }
    if (!formData.Details.VIN) {
      return {
        Field: 'VIN',
        Message: "Please enter the ATV's VIN."
      };
    }
    if (!/^\$?[0-9]+(?:\.[0-9]+)?$/.test(formData.Price)) {
      return {
        Field: 'Price',
        Message: 'Please enter a valid amount.'
      };
    }
    if (formData.AccessoryValue && !/^\$?[0-9]+(?:\.[0-9]+)?$/.test(formData.AccessoryValue)) {
      return {
        Field: 'AccessoryValue',
        Message: 'Please enter a valid amount.'
      };
    }

    return null;
  };

  return (
    <FormContainer>
      <FormRowHeader
        title="Which vehicle are you insuring?"
        description="Specify the manufacturer, model, and model, and purchase year of the vehicle."
      />
      <FormRow breakMobile>
        <FormColumn title="Model Year">
          <TextInput
            error={validationError?.Field === 'ModelYear' && validationError?.Message}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData('ModelYear', (prev) => ({
                ...prev,
                Details: { ...prev.Details, ModelYear: value }
              }));
            }}
            value={props.formData.Details.ModelYear}
            inputMode="numeric"
          />
        </FormColumn>

        <FormColumn title="Manufacturer">
          <TextInput
            error={validationError?.Field === 'Make' && validationError?.Message}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData('Make', (prev) => ({
                ...prev,
                Details: { ...prev.Details, Make: value }
              }));
            }}
            value={props.formData.Details.Make}
          />
        </FormColumn>
        <FormColumn title="Model">
          <TextInput
            error={validationError?.Field === 'Model' && validationError?.Message}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData('Model', (prev) => ({
                ...prev,
                Details: { ...prev.Details, Model: value }
              }));
            }}
            value={props.formData.Details.Model}
          />
        </FormColumn>
      </FormRow>
      <FormRow breakMobile>
        <FormColumn title="Vehicle Type">
          <Select
            options={[
              { value: '' },
              ...(props.formData.ProductType === ProductType.offroad
                ? [
                    {
                      displayValue: 'Sports/Performance ATV (4 wheels)',
                      value: 'sport_performance_atv'
                    },
                    {
                      displayValue: 'Recreation/Utility ATV (4 wheels)',
                      value: 'recreation_utility_atv'
                    },
                    {
                      displayValue: 'Super Utility ATV (4, 6, or 8 wheels)',
                      value: 'super_utility_atv'
                    }
                  ]
                : []),
              ...(props.formData.ProductType === ProductType.motorcycle
                ? [
                    { displayValue: 'Preferred Tour', value: 'preferred_tour' },
                    { displayValue: 'Tour', value: 'tour' },
                    { displayValue: 'Sport Tour', value: 'sport_tour' },
                    { displayValue: 'Non-Touring BMW', value: 'non_touring_bmw' },
                    { displayValue: 'Big Twin', value: 'big_twin' },
                    { displayValue: 'Low Surcharge', value: 'low_surcharge' },
                    { displayValue: 'High Surcharge', value: 'high_surcharge' },
                    {
                      displayValue: 'Professional Racing Surcharge',
                      value: 'professional_racing_surcharge'
                    },
                    { displayValue: 'Naked Sport', value: 'naked_sport' },
                    { displayValue: 'Sportster', value: 'sportster' },
                    { displayValue: 'Cruiser', value: 'cruiser' },
                    { displayValue: 'High Performance Cruiser', value: 'high_performance_cruiser' },
                    { displayValue: 'Moped', value: 'moped' },
                    { displayValue: 'Scooter', value: 'scooter' },
                    { displayValue: 'Street_bike', value: 'street_bike' },
                    { displayValue: 'Offroad Trail Bike', value: 'off_road_trail_bike' },
                    { displayValue: 'Tour Trike', value: 'tour_trike' }
                  ]
                : [])
            ]}
            error={validationError?.Field === 'Type' && validationError?.Message}
            value={props.formData.Details.Type}
            onChange={(value) =>
              setData('Type', (prev) => ({
                ...prev,
                Details: { ...prev.Details, Type: value }
              }))
            }
          />
        </FormColumn>
        <FormColumn title="Cubic Centimeters">
          <TextInput
            error={validationError?.Field === 'CCSize' && validationError?.Message}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData('CCSize', (prev) => ({
                ...prev,
                Details: { ...prev.Details, CCSize: value }
              }));
            }}
            value={props.formData.Details.CCSize}
            inputMode="numeric"
          />
        </FormColumn>
        <FormColumn title="VIN">
          <TextInput
            error={validationError?.Field === 'VIN' && validationError?.Message}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData('VIN', (prev) => ({
                ...prev,
                Details: { ...prev.Details, VIN: value }
              }));
            }}
            value={props.formData.Details.VIN}
          />
        </FormColumn>
      </FormRow>
      <FormRowHeader
        title="Your vehicle's value."
        description="Enter the value of your vehicle, and if applicable, the total value of any accessories that you'd like to insure."
      />
      <FormRow>
        <FormColumn title="Vehicle Value">
          <TextInput
            currency
            error={validationError?.Field === 'Price' && validationError?.Message}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData('Price', (prev) => ({
                ...prev,
                Price: value
              }));
            }}
            value={props.formData.Price}
            inputMode="decimal"
          />
        </FormColumn>
        <FormColumn title="Accessories Value" optional>
          <TextInput
            currency
            error={validationError?.Field === 'AccessoryValue' && validationError?.Message}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData('AccessoryValue', (prev) => ({
                ...prev,
                AccessoryValue: value
              }));
            }}
            value={props.formData.AccessoryValue}
            inputMode="decimal"
          />
        </FormColumn>
      </FormRow>
      <FormRowHeader
        title="More about your vehicle."
        description="Select the statements that apply to your vehicle"
      />
      <FormRow>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {(
            [
              {
                field: 'HasElectronicAlarm',
                name: 'Anti-theft device',
                description: 'This vehicle has a permanently installed anti-theft electronic alarm.'
              },
              {
                field: 'HasTrackingSystem',
                name: 'Tracking device',
                description:
                  'This vehicle has an permanently installed anti-theft tracking system (e.g. GPS).'
              },
              {
                field: 'HasLienHolder',
                name: 'Title lien holder',
                description: 'A lien holder has rights to the title of this vehicle.'
              },
              {
                field: 'IsPerformanceEnhanced',
                name: 'Performance enhanced',
                description:
                  'This vehicle has performance enhancements, like a turbo kit, nitrous oxide kit, etc.'
              },
              {
                field: 'IsStructurallyModified',
                name: 'Structurally modified',
                description:
                  'This vehicle has structural modifications or a frame altered from its original manufacturer specifications.'
              }
            ] as { field: keyof MotorProduct; name: string; description: string }[]
          ).map((option) => (
            <RequirementItemContainer
              key={option.field}
              style={{ padding: '0px', cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation();
                setData('', (prev) => ({
                  ...prev,
                  Details: {
                    ...prev.Details,
                    [option.field]: !props.formData.Details[option.field]
                  }
                }));
              }}
            >
              <RequirementCheckboxContainer>
                <Checkbox
                  label={option.name}
                  checked={!!props.formData.Details[option.field]}
                  onChange={() => {
                    setData('', (prev) => ({
                      ...prev,
                      Details: {
                        ...prev.Details,
                        [option.field]: !props.formData.Details[option.field]
                      }
                    }));
                  }}
                />
              </RequirementCheckboxContainer>
              <RequirementContentContainer>
                <RequirementTitle>{option.name}</RequirementTitle>
                <RequirementDescription>{option.description}</RequirementDescription>
              </RequirementContentContainer>
            </RequirementItemContainer>
          ))}
        </div>
      </FormRow>
      <PageSection noBorder noPadding centered>
        <Button
          icon={<IoArrowForward />}
          primary
          loading={props.loading}
          onClick={(e) => {
            e.preventDefault();

            const err = validate(props.formData);
            if (err) {
              setValidationError(err);
              return;
            }

            props.onContinue({
              Type: props.formData.ProductType,
              SKU: props.formData.SKU || '',
              Name: `${props.formData.Details.Make} ${props.formData.Details.Model}`,
              Description: props.formData.Description || '',
              ImageURL: props.formData.ImageURL || '',
              Quantity: 1,
              Price: {
                Amount: parseFloat(props.formData.Price),
                Currency: 'usd'
              },
              Details: {
                ...props.formData.Details,
                AccessoryValue: {
                  Amount: parseFloat(props.formData.AccessoryValue?.replace(/[^0-9]/, '') || '0'),
                  Currency: 'usd'
                },
                EstimatedAnnualMileage: '0'
              }
            });
          }}
        >
          Continue
        </Button>
      </PageSection>
    </FormContainer>
  );
};
