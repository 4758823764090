import * as React from 'react';

import { matchPath, RouteComponentProps, useHistory } from 'react-router';
import { sdkGetPolicy, sdkUpdatePolicy } from '@oysterjs/core/api/sdk';
import { getAccountSummary } from '@oysterjs/core/api/user';
import { Loadable } from '@oysterjs/ui/Loadable';
import { getToken } from '@oysterjs/core/auth';
import config from '@oysterjs/core/config';
import { ErrorCode, ErrorType, WrappedError } from '@oysterjs/core/errors';
import { HubspotChatContext } from '@oysterjs/core/hubspot';
import { Policy, PolicyType, ValidationError } from '@oysterjs/types';

export interface Page {
  path: string;
  render: (props: RouteComponentProps<{ [key: string]: string | undefined }>) => JSX.Element;
  hasError: (validationError: ValidationError) => boolean;
  icon: JSX.Element;
}

export interface PageProps {
  policy: Policy;
  onNext: () => Promise<boolean>;
  onBack: ((e) => unknown) | null;
  onUpdate: (policy: Policy, forceUpdate?: boolean) => Promise<Policy>;
  validationError?: ValidationError;
  loading: boolean;
}

export const GetPolicyWrapper = (props: {
  policyId?: string;
  children: (data: Policy) => JSX.Element | null;
}): JSX.Element => {
  const history = useHistory();
  const promise = sdkGetPolicy(props.policyId || '').catch((e) => {
    const err = WrappedError.asWrappedError(e);
    if (err.type() === ErrorType.preconditionFailed && err.code() === ErrorCode.alreadySubmitted) {
      history.push(`/policy/${props.policyId}/complete`);
    }
    return null;
  });

  return <Loadable request={promise}>{(data) => data && props.children(data)}</Loadable>;
};

export const ExistingUserWrapper = (props: { children: (e) => JSX.Element }): JSX.Element => {
  if (config().serviceName !== 'dashboard' || getToken() == null) {
    return props.children(null);
  }

  const [promise] = React.useState(getAccountSummary());

  // React.useEffect(() => {
  //   const onFocus = () => setPromise(getAccountSummary());
  //   window.addEventListener('focus', onFocus);
  //   return () => window.removeEventListener('focus', onFocus);
  // }, []);

  return <Loadable request={promise}>{(account) => props.children(account)}</Loadable>;
};

export const PageWrapper = (props: {
  policy: Policy;
  pages: Page[];
  component: React.FunctionComponent<React.PropsWithChildren<PageProps>>;
}) => {
  const history = useHistory();
  const hubspot = React.useContext(HubspotChatContext);

  const [loading, setLoading] = React.useState(false);
  const [policy, setPolicy] = React.useState(props.policy);
  const [validationError, setValidationError] = React.useState<ValidationError>();
  const productType = history.location.pathname.split('/')[2];

  const currentPageIndex = props.pages.findIndex((page) =>
    matchPath(window.location.pathname, {
      path: page.path.replace('$PRODUCT_TYPE', productType),
      exact: true
    })
  );
  const onUpdate = async (policy: Policy, forceUpdate?: boolean) => {
    if (forceUpdate) {
      setLoading(true);
      try {
        const { Policy } = await sdkUpdatePolicy(policy);
        if (Policy) {
          setPolicy(Policy);
        }
        return Policy;
      } catch {
        // TODO: render error
        return policy;
      } finally {
        setLoading(false);
      }
    }
    setPolicy(policy);
    return policy;
  };

  const onBack = (e) => {
    e.preventDefault();
    if (currentPageIndex > 0) {
      history.push(
        props.pages[currentPageIndex - 1].path
          .replace(':id', policy.ID)
          .replace('$PRODUCT_TYPE', productType)
      );
    }
  };

  const onNext = async () => {
    setLoading(true);
    try {
      const { Policy, NextValidationError } = await sdkUpdatePolicy(policy, true);
      if (NextValidationError) {
        setValidationError(NextValidationError);
        let nextPageIndex = props.pages.findIndex((page) => page.hasError(NextValidationError));
        if (nextPageIndex === -1 || nextPageIndex - currentPageIndex > 1) {
          nextPageIndex = currentPageIndex + 1;
        }
        if (nextPageIndex !== currentPageIndex && nextPageIndex < props.pages.length) {
          history.push(
            props.pages[nextPageIndex].path
              .replace(':id', policy.ID)
              .replace('$PRODUCT_TYPE', productType)
          );
        }
      } else {
        setValidationError(undefined);
        if (currentPageIndex < props.pages.length - 1) {
          history.push(
            props.pages[currentPageIndex + 1].path
              .replace(':id', policy.ID)
              .replace('$PRODUCT_TYPE', productType)
          );
        }
      }
      if (Policy) {
        setPolicy(Policy);
        hubspot?.identify(
          policy?.Underwriting?.Insured?.Email,
          policy?.Underwriting?.Insured?.FirstName,
          policy?.Underwriting?.Insured?.LastName
        );
      }
      return true;
    } catch (e) {
      const err = WrappedError.asWrappedError(e);
      // Send the user to a page that renders the right error
      // based on err.Type and optionally err.Field
      if (err.type() === ErrorType.underwritingError) {
        // Show the user an error indicating we can't offer them insurance.
        window.localStorage.setItem(err.type(), JSON.stringify(err));
        switch (props.policy.Type) {
          case PolicyType.chubbJewelry:
            history.push(`/app/${props.policy.InsuredItems[0].Type}/v2/ineligible`);
            break;
          default:
            history.push(`/app/${props.policy.InsuredItems[0].Type}/ineligible`);
        }
      }

      if (err.type() === ErrorType.validationError) {
        // Show the user the error in err.Message and highlight the
        // field corresponding to err.Field.
        setValidationError(err.getValidationError());
        // find page to go to if any
        let nextPageIndex = props.pages.findIndex((page) =>
          page.hasError(err.getValidationError())
        );
        if (nextPageIndex === -1 || nextPageIndex - currentPageIndex > 1) {
          nextPageIndex = currentPageIndex + 1;
        }
        if (nextPageIndex !== currentPageIndex && nextPageIndex < props.pages.length) {
          history.push(
            props.pages[nextPageIndex].path
              .replace(':id', policy.ID)
              .replace('$PRODUCT_TYPE', productType)
          );
        }
      }
    } finally {
      setLoading(false);
    }

    return false;
  };

  // Initial page load manual identification
  React.useEffect(() => {
    hubspot?.identify(
      policy?.Underwriting?.Insured?.Email,
      policy?.Underwriting?.Insured?.FirstName,
      policy?.Underwriting?.Insured?.LastName
    );
  }, [hubspot]);

  // React.useEffect(() => {
  //   sdkUpdatePolicy(policy, true).catch((err) => {
  //     if (err.Type === 'validation_error') {
  //       // Show the user the error in err.Message and highlight the
  //       // field corresponding to err.Field.
  //       setValidationError(err);

  //       // find page to go to if any
  //       let nextPageIndex = pages.findIndex((page) => page.hasError(err));
  //       if (nextPageIndex === -1 || nextPageIndex - currentPageIndex > 1) {
  //         nextPageIndex = currentPageIndex + 1;
  //       }
  //       if (nextPageIndex !== currentPageIndex && nextPageIndex < pages.length) {
  //         history.push(pages[nextPageIndex].path.replace(':id', policy.ID));
  //       }
  //     }
  //   });
  // }, [currentPageIndex]);

  return React.createElement(props.component, {
    loading,
    policy,
    validationError,
    onNext,
    onBack,
    onUpdate
  });
};
