import * as React from 'react';
import {
  Switch,
  Route as BrowserRoute,
  Redirect,
  useRouteMatch,
  useHistory,
  matchPath
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
const Route = Sentry.withSentryRouting(BrowserRoute);
import { Progress } from '@oysterjs/ui/Form/progress';

import { PageContainer, PageSection } from '@oysterjs/ui/Page';
import {
  ValidationError,
  Product,
  JewelryProductValidationKeys,
  AttachmentFile,
  DefaultFileRoles,
  ChubbJewelryCriteria,
  PolicyType
} from '@oysterjs/types';
import { Select } from '@oysterjs/ui/Form/select';
import { ErrorDisplay, TextAreaInput, TextInput } from '@oysterjs/ui/Form/text';
import { Button, ButtonContainer, LinkComponent } from '@oysterjs/ui/Button';
import {
  IoArrowForward,
  IoBagCheckOutline,
  IoPricetagsOutline,
  IoDocumentTextOutline,
  IoHomeOutline,
  IoPersonOutline,
  IoArrowBack,
  IoAddOutline,
  IoCloseOutline
} from 'react-icons/io5';
import {
  sdkCreatePolicy,
  sdkCreateTemporaryAttachments,
  sdkGetPaymentSession,
  sdkUpdatePolicy,
  sdkValidatePolicy
} from '@oysterjs/core/api/sdk';
import {
  RequirementCheckboxContainer,
  RequirementContentContainer,
  RequirementDescription,
  RequirementItemContainer,
  RequirementTitle
} from '@oysterjs/ui/common';
import { AddressInputForm } from '@oysterjs/ui/Form/address';
import { Checkbox } from '@oysterjs/ui/Form/checkbox';
import { FormColumn, FormContainer, FormRow, FormRowHeader } from '@oysterjs/ui/Form/builder';
import { ExistingUserWrapper, GetPolicyWrapper, Page, PageProps, PageWrapper } from '../../page';
import { getPaymentPage } from '../../payment';
import { getCoveragePage } from '../../coverage';
import { JewelryProduct, Policy } from '@oysterjs/types';
import { useUrlData } from '@oysterjs/core/url';
import apm from '@oysterjs/core/apm';
import { CollectJewelryInfo, JewelryFormData } from './../collect';
import { ProductInfoPage } from '../../product';
import { DateOfBirthInput } from '@oysterjs/ui/Form/dob';
import { useReferralChannel } from '@oysterjs/core/analytics/attribution';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';
import { Switch as ToggleSwitch } from '@oysterjs/ui/Form/switch';
import { FraudDisclosuresByState } from './disclosures/disclosures';
import { userGetPaymentSession } from '@oysterjs/core/api/user';
import { OysterLogo } from '@oysterjs/ui/Logo';

function requiresAUI(policy: Policy, occupationDetail?: string): boolean {
  switch (policy.Underwriting.Insured.Occupation) {
    case 'Arts/Entertainment/Media':
      if (
        occupationDetail &&
        [
          'Actor/Actress',
          'Art Dealer',
          'Broadcaster/Reporter',
          'Director – TV/Film/Stage',
          'Editor',
          'Fashion Designer',
          'Journalist',
          'Model',
          'Musician',
          'News Media',
          'Publisher',
          'Radio (Director,Producer,Personailty)',
          'TV/Film/Stage',
          'Writer'
        ].includes(occupationDetail)
      ) {
        return true;
      }
      break;
    case 'Clergy':
      if (occupationDetail && ['Evangelist'].includes(occupationDetail)) {
        return true;
      }
      break;
    case 'Government':
      if (occupationDetail && ['Diplomat', 'Politician', 'Royalty'].includes(occupationDetail)) {
        return true;
      }
      break;
    case 'Military':
      if (occupationDetail && ['Joint Chiefs of Staff'].includes(occupationDetail)) {
        return true;
      }
      break;
    case 'Retired':
      if (occupationDetail && ['Arts/Entertainment/Media', 'Sports'].includes(occupationDetail)) {
        return true;
      }
      break;
    case 'Sports':
      if (
        occupationDetail &&
        ['Broadcaster/Journalist', 'Manager/Promoter', 'Owner', 'Professional Athlete'].includes(
          occupationDetail
        )
      ) {
        return true;
      }
      break;
    case 'Personal Care/Services':
      if (occupationDetail && ['Celebrity Consultant'].includes(occupationDetail)) {
        return true;
      }
      break;
    case 'Not Currently Employed':
      if (
        occupationDetail &&
        ['Arts/Entertainment/Media', 'Sports', 'Volunteer'].includes(occupationDetail)
      ) {
        return true;
      }
      break;
    default:
      break;
  }

  const totalValue = policy.InsuredItems.reduce(
    (partialValue, item) => partialValue + item.Price.Amount,
    0
  );
  if (totalValue > 200000) return true;
  return false;
}

function getOccupationDetailOpts(
  occupation: string
): { displayValue: string; value: string }[] | void {
  switch (occupation) {
    case 'Arts/Entertainment/Media':
      return [
        { displayValue: '', value: '' },
        { displayValue: 'Actor/Actress', value: 'Actor/Actress' },
        { displayValue: 'Agent', value: 'Agent' },
        { displayValue: 'Art Dealer', value: 'Art Dealer' },
        { displayValue: 'Broadcaster/Reporter', value: 'Broadcaster/Reporter' },
        { displayValue: 'Business Owner', value: 'Business Owner' },
        { displayValue: 'Curator', value: 'Curator' },
        { displayValue: 'Director - TV/Film/Stage', value: 'Director - TV/Film/Stage' },
        { displayValue: 'Editor', value: 'Editor' },
        { displayValue: 'Fashion Designer', value: 'Fashion Designer' },
        { displayValue: 'Journalist', value: 'Journalist' },
        { displayValue: 'Manager', value: 'Manager' },
        { displayValue: 'Model', value: 'Model' },
        { displayValue: 'Musician', value: 'Musician' },
        { displayValue: 'News Media', value: 'News Media' },
        { displayValue: 'Photographer', value: 'Photographer' },
        { displayValue: 'Publisher', value: 'Publisher' },
        {
          displayValue: 'Radio (Director, Producer, Personality)',
          value: 'Radio (Director, Producer, Personality)'
        },
        { displayValue: 'TV/Film/Stage', value: 'TV/Film/Stage' },
        { displayValue: 'Writer', value: 'Writer' },
        { displayValue: 'Other', value: 'Other' }
      ];
    case 'Retired':
      return [
        { displayValue: '', value: '' },
        { displayValue: 'Arts/Entertainment/Media', value: 'Arts/Entertainment/Media' },
        { displayValue: 'Automotive', value: 'Automotive' },

        { displayValue: 'Education', value: 'Education' },
        { displayValue: 'Engineering', value: 'Engineering' },
        { displayValue: 'Finance/Insurance', value: 'Finance/Insurance' },
        { displayValue: 'Food/Hotel', value: 'Food/Hotel' },
        { displayValue: 'Government', value: 'Government' },
        { displayValue: 'Info/Communication Tech', value: 'Info/Communication Tech' },
        { displayValue: 'Legal', value: 'Legal' },
        { displayValue: 'Manufacturing', value: 'Manufacturing' },
        { displayValue: 'Medical/Health', value: 'Medical/Health' },
        { displayValue: 'Police/Fire/Security', value: 'Police/Fire/Security' },
        { displayValue: 'Real Estate/Maintenance', value: 'Real Estate/Maintenance' },
        { displayValue: 'Sales/Advertising/Retail', value: 'Sales/Advertising/Retail' },
        { displayValue: 'Sports', value: 'Sports' },
        { displayValue: 'Other', value: 'Other' }
      ];
    case 'Personal Care/Services':
      return [
        { displayValue: '', value: '' },
        { displayValue: 'Celebrity Consultant', value: 'Celebrity Consultant' },
        { displayValue: 'Executive', value: 'Executive' },
        { displayValue: 'Hair Stylist/Barber', value: 'Hair Stylist/Barber' },
        { displayValue: 'Landscaper/Gardener', value: 'Landscaper/Gardener' },
        { displayValue: 'Manager', value: 'Manager' },
        { displayValue: 'Other', value: 'Other' },
        { displayValue: 'Property Manager', value: 'Property Manager' },
        { displayValue: 'Trainer', value: 'Trainer' }
      ];
    case 'Not Currently Employed':
      return [
        { displayValue: '', value: '' },
        { displayValue: 'Arts/Entertainment/Media', value: 'Arts/Entertainment/Media' },
        { displayValue: 'Automotive', value: 'Automotive' },
        { displayValue: 'Education', value: 'Education' },
        { displayValue: 'Engineering', value: 'Engineering' },
        { displayValue: 'Finance/Insurance', value: 'Finance/Insurance' },
        { displayValue: 'Food/Hotel', value: 'Food/Hotel' },
        { displayValue: 'Government', value: 'Government' },
        { displayValue: 'Info/Communication Tech', value: 'Info/Communication Tech' },
        { displayValue: 'Legal', value: 'Legal' },
        { displayValue: 'Manufacturing', value: 'Manufacturing' },
        { displayValue: 'Medical/Health/Pharma', value: 'Medical/Health/Pharma' },
        { displayValue: 'Other', value: 'Other' },
        { displayValue: 'Police/Fire/Security', value: 'Police/Fire/Security' },
        { displayValue: 'Real Estate/Maintenance', value: 'Real Estate/Maintenance' },
        { displayValue: 'Sales/Advertising/Retail', value: 'Sales/Advertising/Retail' },
        { displayValue: 'Sports', value: 'Sports' },
        { displayValue: 'Volunteer', value: 'Volunteer' }
      ];
    case 'Military':
      return [
        { displayValue: '', value: '' },
        { displayValue: 'Air Force', value: 'Air Force' },
        { displayValue: 'Army', value: 'Army' },
        { displayValue: 'Coast Guard', value: 'Coast Guard' },
        { displayValue: 'Joint Chiefs of Staff', value: 'Joint Chiefs of Staff' },
        { displayValue: 'Marine', value: 'Marine' },
        { displayValue: 'Navy', value: 'Navy' }
      ];
    case 'Clergy':
      return [
        { displayValue: '', value: '' },
        { displayValue: 'Cleric', value: 'Cleric' },
        { displayValue: 'Deacon', value: 'Deacon' },
        { displayValue: 'Evangelist', value: 'Evangelist' },
        { displayValue: 'Minister', value: 'Minister' },
        { displayValue: 'Missionary', value: 'Missionary' },
        { displayValue: 'Priest', value: 'Priest' },
        { displayValue: 'Rabbi', value: 'Rabbi' },
        { displayValue: 'Other', value: 'Other' }
      ];
    case 'Government':
      return [
        { displayValue: '', value: '' },
        { displayValue: 'Administrator', value: 'Administrator' },
        { displayValue: 'Clerk', value: 'Clerk' },
        { displayValue: 'Consultant/Advisor', value: 'Consultant/Advisor' },
        { displayValue: 'Diplomat', value: 'Diplomat' },
        { displayValue: 'Judge', value: 'Judge' },
        { displayValue: 'Lobbyist', value: 'Lobbyist' },
        { displayValue: 'Manager', value: 'Manager' },
        {
          displayValue: 'Official (County, State, Federal)',
          value: 'Official (County, State, Federal)'
        },
        { displayValue: 'Other', value: 'Other' },
        { displayValue: 'Park/Forest Ranger', value: 'Park/Forest Ranger' },
        { displayValue: 'Politician', value: 'Politician' },
        { displayValue: 'Royalty', value: 'Royalty' },
        { displayValue: 'Translator', value: 'Translator' }
      ];
    case 'Sports':
      return [
        { displayValue: '', value: '' },
        { displayValue: 'Agent', value: 'Agent' },
        { displayValue: 'Analyst/Programmer', value: 'Analyst/Programmer' },
        { displayValue: 'Broadcaster/Journalist', value: 'Broadcaster/Journalist' },
        { displayValue: 'Executive', value: 'Executive' },
        { displayValue: 'Manager/Promoter', value: 'Manager/Promoter' },
        { displayValue: 'Other', value: 'Other' },
        { displayValue: 'Owner', value: 'Owner' },
        {
          displayValue: 'Professional Athlete',
          value: 'Professional Athlete'
        },
        { displayValue: 'Sales', value: 'Sales' },
        { displayValue: 'Trainer/Instructor', value: 'Trainer/Instructor' }
      ];
    default:
      return;
  }
}

function showOccupationDetails(occupation: string): boolean {
  switch (occupation) {
    case 'Arts/Entertainment/Media':
    case 'Retired':
    case 'Personal Care/Services':
    case 'Not Currently Employed':
    case 'Military':
    case 'Clergy':
    case 'Government':
    case 'Sports':
      return true;
    default:
      return false;
  }
}

const CollectJewelryInfoPage: React.FunctionComponent<
  React.PropsWithChildren<{ policy?: Policy }>
> = (props) => {
  const urlData = useUrlData<JewelryFormData>();
  const history = useHistory();
  const referralChannel = useReferralChannel();

  const onSubmit = (product: Product) => {
    const uploadAttachmentHandler = () => {
      const details = product.Details as JewelryProduct;
      const newFiles = details._AppraisalFiles;
      if (newFiles && newFiles.length > 0) {
        return sdkCreateTemporaryAttachments(
          DefaultFileRoles.purchaseReceipt,
          newFiles,
          details.AppraisalFileIDs
        );
      }
      return Promise.resolve(new Array<AttachmentFile>());
    };

    return uploadAttachmentHandler().then((filesMetadata) => {
      const updatedDetails = {
        ...product.Details,
        _AppraisalFiles: undefined,
        AppraisalFileIDs: filesMetadata
          .map((metadata) => metadata.ID)
          .concat((product.Details as JewelryProduct).AppraisalFileIDs || [])
      };

      if (props.policy) {
        return sdkUpdatePolicy(
          {
            ...props.policy,
            InsuredItems: [
              {
                ...product,
                Details: updatedDetails
              }
            ]
          },
          true
        );
      }
      return sdkCreatePolicy(
        [
          {
            ...product,
            Details: updatedDetails
          }
        ],
        {
          integrationID: urlData.integrationID,
          referralChannel
        }
      );
    });
  };

  const onContinue = (policy?: Policy) => {
    if (!props.policy) {
      history.replace(`/app/jewelry/${policy?.ID}`);
    } else if (
      props.policy.Type == PolicyType.minicoJewelry ||
      policy?.Type == PolicyType.minicoJewelry
    ) {
      history.push(`/app/jewelry/${props.policy?.ID || policy?.ID}/location`);
    } else {
      history.push(`/app/jewelry/v2/${props.policy?.ID || policy?.ID}/criteria`);
    }
  };

  const price = props.policy?.InsuredItems?.[0].Price?.Amount;

  if (urlData?.appData && !urlData.appData.Details) {
    urlData.appData.Details = {} as JewelryProduct;
  }

  const initialFormData = props.policy
    ? {
        Price: price?.toString() || '',
        Name: props.policy.InsuredItems[0].Name,
        Description: props.policy.InsuredItems[0].Description,
        Details: props.policy.InsuredItems[0].Details as JewelryProduct
      }
    : urlData?.appData
      ? urlData.appData
      : { Price: '', Details: {} as JewelryProduct };

  return (
    <ProductInfoPage
      title="Tell us about your jewelry"
      description="We need a bit of information about your jewelry before we can show you coverage options."
      initialFormData={initialFormData}
      hasError={pages[0].hasError}
      onSubmit={onSubmit}
      onContinue={onContinue}
      component={CollectJewelryInfo}
    />
  );
};

export const CriteriaPage: React.FunctionComponent<React.PropsWithChildren<PageProps>> = (
  props
) => (
  <PageSection>
    <h1>A bit about you</h1>
    <p>Tell us a little bit about yourself.</p>
    <JewelryCriteriaPage {...props} />
  </PageSection>
);

const JewelryCriteriaPage: React.FunctionComponent<React.PropsWithChildren<PageProps>> = (
  props
) => {
  const [validationError, setValidationError] = React.useState<ValidationError>();
  const [inputChanged, setInputChanged] = React.useState(false);
  const criteria = props.policy.Underwriting.Criteria as ChubbJewelryCriteria;

  React.useEffect(() => {
    if (!props.loading) {
      setInputChanged(false);
    }
  }, [props.loading]);

  React.useEffect(() => {
    setValidationError(props.validationError);
  }, [props.validationError]);

  const onChange = <T,>(k: string, v: T) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.policy,
      Underwriting: {
        ...props.policy.Underwriting,
        Criteria: {
          ...criteria,
          [k]: v
        }
      }
    });
  };

  const onChangeInsured = <T,>(k: string, v: T) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.policy,
      Underwriting: {
        ...props.policy.Underwriting,
        Insured: {
          ...props.policy.Underwriting.Insured,
          [k]: v
        }
      }
    });
  };

  const onChangeInsuredDateOfBirth = (day: string, month: string, year: string) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.policy,
      Underwriting: {
        ...props.policy.Underwriting,
        Insured: {
          ...props.policy.Underwriting.Insured,
          DateOfBirth: {
            Day: day,
            Month: month,
            Year: year
          }
        }
      }
    });
  };

  const onChangePriorLoss = <T,>(lossIdentifier: string, k: string, v: T) => {
    setInputChanged(true);
    const target = criteria.PriorLosses.find(
      (priorLoss) => priorLoss.lossIdentifier === lossIdentifier
    );

    if (target) {
      const index = criteria.PriorLosses.indexOf(target);
      const updatedLoss = {
        ...target,
        [k]: v
      };
      const updatedLosses = new Array(...criteria.PriorLosses);
      updatedLosses[index] = updatedLoss;

      props.onUpdate({
        ...props.policy,
        Underwriting: {
          ...props.policy.Underwriting,
          Criteria: {
            ...criteria,
            PriorLosses: updatedLosses
          }
        }
      });
    }
  };

  return (
    <FormContainer
      onSubmit={(e) => {
        e.preventDefault();
        props.onNext();
      }}
    >
      <FormRowHeader title="Personal information" />
      <FormRow>
        <FormColumn title="First name">
          <TextInput
            error={
              !inputChanged && validationError?.SubField === 'FirstName' && validationError?.Message
            }
            value={props.policy.Underwriting.Insured.FirstName}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeInsured('FirstName', value);
            }}
            autoComplete="given-name"
          />
        </FormColumn>
        <FormColumn title="Last name">
          <TextInput
            error={
              !inputChanged && validationError?.SubField === 'LastName' && validationError?.Message
            }
            value={props.policy.Underwriting.Insured.LastName}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeInsured('LastName', value);
            }}
            autoComplete="family-name"
          />
        </FormColumn>
      </FormRow>
      <FormRow breakMobile>
        <FormColumn title="Email address">
          <TextInput
            error={
              !inputChanged && validationError?.SubField === 'Email' && validationError?.Message
            }
            value={props.policy.Underwriting.Insured.Email}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeInsured('Email', value);
            }}
            inputMode="email"
            autoComplete="email"
          />
        </FormColumn>
        <FormColumn title="Phone number">
          <TextInput
            error={
              !inputChanged && validationError?.SubField === 'Phone' && validationError?.Message
            }
            value={props.policy.Underwriting.Insured.Phone}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeInsured('Phone', value);
            }}
            inputMode="tel"
            autoComplete="tel"
          />
        </FormColumn>
      </FormRow>
      <FormRow breakMobile>
        <FormColumn title="Date of birth">
          <DateOfBirthInput
            error={
              !inputChanged &&
              props.validationError?.Field === 'Insured' &&
              props.validationError?.SubField === 'DateOfBirth' &&
              props.validationError?.Message
            }
            initialValue={[
              props.policy.Underwriting.Insured.DateOfBirth?.Day || '',
              props.policy.Underwriting.Insured.DateOfBirth?.Month || '',
              props.policy.Underwriting.Insured.DateOfBirth?.Year || ''
            ]}
            onChange={onChangeInsuredDateOfBirth}
          />
        </FormColumn>
        <FormColumn title="Occupation">
          <Select
            options={[
              { displayValue: '', value: '' },
              { displayValue: 'Agriculture', value: 'Agriculture' },
              { displayValue: 'Arts/Entertainment/Media', value: 'Arts/Entertainment/Media' },
              { displayValue: 'Automotive', value: 'Automotive' },
              { displayValue: 'Clergy', value: 'Clergy' },
              { displayValue: 'Construction', value: 'Construction' },
              { displayValue: 'Education', value: 'Education' },
              { displayValue: 'Engineering', value: 'Engineering' },
              { displayValue: 'Finance/ Insurance', value: 'Finance/Insurance' },
              { displayValue: 'Food/Hotel', value: 'Food/Hotel' },
              { displayValue: 'Government', value: 'Government' },
              { displayValue: 'Homemaker', value: 'Homemaker' },
              { displayValue: 'Info/Communication Tech', value: 'Info/Communication Tech' },
              { displayValue: 'Legal', value: 'Legal' },
              { displayValue: 'Manufacturing', value: 'Manufacturing' },
              { displayValue: 'Medical/Healthcare', value: 'Medical/Healthcare' },
              { displayValue: 'Military', value: 'Military' },
              { displayValue: 'Not Currently Employed', value: 'Not Currently Employed' },
              { displayValue: 'Other', value: 'Other' },
              { displayValue: 'Personal Care/Services', value: 'Personal Care/Services' },
              { displayValue: 'Police/ Fire/Security', value: 'Police/Fire/Security' },
              { displayValue: 'Real Estate/Maintenance', value: 'Real Estate/Maintenance' },
              { displayValue: 'Retired', value: 'Retired' },
              { displayValue: 'Sales/ Advertising/Retail', value: 'Sales/Advertising/Retail' },
              { displayValue: 'Sports', value: 'Sports' },
              { displayValue: 'Student', value: 'Student' },
              { displayValue: 'Trades', value: 'Trades' },
              { displayValue: 'Transportation/Delivery', value: 'Transportation/Delivery' }
            ]}
            onChange={(value) => {
              onChangeInsured('Occupation', value);
            }}
            error={
              !inputChanged &&
              validationError?.SubField === 'Occupation' &&
              validationError?.Message
            }
            value={props.policy.Underwriting.Insured.Occupation}
          />
        </FormColumn>
      </FormRow>
      {showOccupationDetails(props.policy.Underwriting.Insured.Occupation || '') && (
        <FormRow breakMobile singleItem>
          <FormColumn title="Occupation Details">
            <Select
              options={
                getOccupationDetailOpts(props.policy.Underwriting.Insured.Occupation || '') || []
              }
              onChange={(value) => {
                onChange('OccupationDetails', value);
              }}
              error={
                !inputChanged &&
                validationError?.Field === 'OccupationDetails' &&
                validationError?.Message
              }
              value={criteria.OccupationDetails}
            />
          </FormColumn>
        </FormRow>
      )}
      {props.policy.Underwriting.Insured.Occupation == 'Other' && (
        <FormRow breakMobile singleItem>
          <FormColumn title="Occupation Details">
            <TextInput
              error={
                !inputChanged &&
                validationError?.Field === 'OtherOccupationDetails' &&
                validationError?.Message
              }
              value={criteria.OtherOccupationDetails}
              onChange={(e) => {
                const value = e.currentTarget.value;
                onChange('OtherOccupationDetails', value);
              }}
              autoComplete="occupation-details"
            />
          </FormColumn>
        </FormRow>
      )}
      <FormRow>
        <RequirementItemContainer
          style={{ padding: '0px', paddingTop: '16px', cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            onChange('HasAdditionalInsured', !criteria.HasAdditionalInsured);
          }}
        >
          <RequirementCheckboxContainer>
            <Checkbox
              label={'AdditionalInsuredCheckbox'}
              checked={criteria.HasAdditionalInsured}
              onChange={() => {
                onChange('HasAdditionalInsured', !criteria.HasAdditionalInsured);
              }}
            />
          </RequirementCheckboxContainer>
          <RequirementContentContainer>
            <RequirementTitle>Add another person to this policy</RequirementTitle>
            <RequirementDescription>
              This ensures that coverage extends to this person in the event of a loss. For example,
              if this is a wedding or engagement ring, you'd likely want to add your spouse or the
              ring-wearer if that person is not you.
            </RequirementDescription>
          </RequirementContentContainer>
        </RequirementItemContainer>
      </FormRow>
      {criteria.HasAdditionalInsured && (
        <>
          <FormRowHeader title="Additional insured information" />
          <FormRow>
            <FormColumn title="First name">
              <TextInput
                error={
                  !inputChanged &&
                  validationError?.Field === 'AdditionalInsuredFirstName' &&
                  validationError?.Message
                }
                value={criteria.AdditionalInsuredFirstName}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  onChange('AdditionalInsuredFirstName', value);
                }}
                autoComplete="given-name"
              />
            </FormColumn>
            <FormColumn title="Last name">
              <TextInput
                error={
                  !inputChanged &&
                  validationError?.Field === 'AdditionalInsuredLastName' &&
                  validationError?.Message
                }
                value={criteria.AdditionalInsuredLastName}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  onChange('AdditionalInsuredLastName', value);
                }}
                autoComplete="family-name"
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn title="Date of birth">
              <DateOfBirthInput
                error={
                  !inputChanged &&
                  props.validationError?.Field === 'AdditionalInsuredDateOfBirth' &&
                  props.validationError?.SubField === 'DateOfBirth' &&
                  props.validationError?.Message
                }
                initialValue={[
                  criteria.AdditionalInsuredDateOfBirth?.Day || '',
                  criteria.AdditionalInsuredDateOfBirth?.Month || '',
                  criteria.AdditionalInsuredDateOfBirth?.Year || ''
                ]}
                onChange={(day, month, year) =>
                  onChange('AdditionalInsuredDateOfBirth', {
                    Day: day,
                    Month: month,
                    Year: year
                  })
                }
              />
            </FormColumn>
            <FormColumn title="Occupation" style={{ maxWidth: 'calc(50% - 8px)' }}>
              <Select
                options={[
                  { displayValue: '', value: '' },
                  { displayValue: 'Agriculture', value: 'Agriculture' },
                  { displayValue: 'Arts/ Entertainment/Media', value: 'Arts/Entertainment/Media' },
                  { displayValue: 'Automotive', value: 'Automotive' },
                  { displayValue: 'Clergy', value: 'Clergy' },
                  { displayValue: 'Education', value: 'Education' },
                  { displayValue: 'Engineering', value: 'Engineering' },
                  { displayValue: 'Finance/ Insurance', value: 'Finance/Insurance' },
                  { displayValue: 'Food/Hotel', value: 'Food/Hotel' },
                  { displayValue: 'Government', value: 'Government' },
                  { displayValue: 'Homemaker', value: 'Homemaker' },
                  { displayValue: 'Info/Communication Tech', value: 'Info/Communication Tech' },
                  { displayValue: 'Legal', value: 'Legal' },
                  { displayValue: 'Manufacturing', value: 'Manufacturing' },
                  { displayValue: 'Medical/Healthcare', value: 'Medical/Healthcare' },
                  { displayValue: 'Military', value: 'Military' },
                  { displayValue: 'Not Currently Employed', value: 'Not Currently Employed' },
                  { displayValue: 'Other', value: 'Other' },
                  { displayValue: 'Personal Care/Services', value: 'Personal Care/Services' },
                  { displayValue: 'Police/ Fire/Security', value: 'Police/Fire/Security' },
                  { displayValue: 'Real Estate/Maintenance', value: 'Real Estate/Maintenance' },
                  { displayValue: 'Retired', value: 'Retired' },
                  { displayValue: 'Sales/ Advertising/Retail', value: 'Sales/Advertising/Retail' },
                  { displayValue: 'Sports', value: 'Sports' },
                  { displayValue: 'Student', value: 'Student' },
                  { displayValue: 'Trades', value: 'Trades' },
                  { displayValue: 'Transportation/Delivery', value: 'Transportation/Delivery' }
                ]}
                onChange={(value) => {
                  onChange('AdditionalInsuredOccupation', value);
                }}
                error={
                  !inputChanged &&
                  validationError?.Field === 'AdditionalInsuredOccupation' &&
                  validationError?.Message
                }
                value={criteria.AdditionalInsuredOccupation}
              />
            </FormColumn>
            {showOccupationDetails(criteria.AdditionalInsuredOccupation) && (
              <FormColumn title="Occupation Details" style={{ maxWidth: 'calc(50% - 8px)' }}>
                <Select
                  options={getOccupationDetailOpts(criteria.AdditionalInsuredOccupation) || []}
                  onChange={(value) => {
                    onChange('AdditionalInsuredOccupationDetails', value);
                  }}
                  error={
                    !inputChanged &&
                    validationError?.Field === 'AdditionalInsuredOccupationDetails' &&
                    validationError?.Message
                  }
                  value={criteria.AdditionalInsuredOccupationDetails}
                />
              </FormColumn>
            )}
          </FormRow>
        </>
      )}
      {requiresAUI(props.policy, criteria.OccupationDetails) && (
        <>
          <FormRowHeader title="How often do you travel?" />
          <FormRow>
            <FormColumn style={{ maxWidth: 'calc(50% - 8px)' }}>
              <Select
                options={[
                  { displayValue: '', value: '' },
                  {
                    displayValue: 'Less than 5 times per year',
                    value: 'Less than 5 times per year'
                  },
                  { displayValue: '5 - 10 times per year', value: '5 to 10 times per year' },
                  { displayValue: '10 or more times per year', value: '10 or more times per year' }
                ]}
                onChange={(value) => {
                  onChange('InsuredTravel', value);
                }}
                error={
                  !inputChanged &&
                  validationError?.Field === 'InsuredTravel' &&
                  validationError?.Message
                }
                value={criteria.InsuredTravel}
              />
            </FormColumn>
          </FormRow>
          <FormRowHeader
            title="What is the average value of jewelry taken while traveling?"
            description="Please leave this field empty if you do not travel with jewelry."
          />
          <FormRow>
            <FormColumn>
              <TextInput
                currency={true}
                style={{ maxWidth: '200px' }}
                error={
                  validationError?.Field === 'AverageJewelryValueOnTravel' &&
                  validationError?.Message
                }
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  onChange('AverageJewelryValueOnTravel', parseFloat(value.replace(/[^.0-9]/, '')));
                }}
                value={criteria.AverageJewelryValueOnTravel || ''}
                inputMode="decimal"
              />
            </FormColumn>
          </FormRow>
          <FormRowHeader title="How do you protect your jewelry while traveling?" />
          <FormRow>
            <FormColumn>
              <TextAreaInput
                error={
                  validationError?.Field === 'JewelrySafeguardOnTravel' && validationError?.Message
                }
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  onChange('JewelrySafeguardOnTravel', value);
                }}
                value={criteria.JewelrySafeguardOnTravel}
                style={{ resize: 'none', width: '100%' }}
                rows={5}
              />
            </FormColumn>
          </FormRow>
          <FormRowHeader title="How do you protect jewelry left in your residence while traveling?" />
          <FormRow>
            <FormColumn>
              <TextAreaInput
                error={
                  validationError?.Field === 'JewelrySafeguardAtHomeInsuredOnTravel' &&
                  validationError?.Message
                }
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  onChange('JewelrySafeguardAtHomeInsuredOnTravel', value);
                }}
                value={criteria.JewelrySafeguardAtHomeInsuredOnTravel}
                style={{ resize: 'none', width: '100%' }}
                rows={5}
              />
            </FormColumn>
          </FormRow>
        </>
      )}
      <FormRowHeader
        title="Prior losses"
        description="Please describe any personal property losses or claims you've experienced in the past 5 years."
      />
      {criteria.PriorLosses.length > 0 &&
        criteria.PriorLosses.map((priorLoss, index) => {
          const dateOfLoss = new Date(priorLoss.dateOfLoss);
          const hasDateOfLoss = dateOfLoss.getTime() > 0;

          const paidDate = new Date(priorLoss.lossPaidDate);
          const hasPaidDate = paidDate.getTime() > 0;

          const key = priorLoss.lossIdentifier;
          const validationField = 'PriorLosses' + index;

          return (
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '16px' }}
              key={key}
            >
              <FormRow breakMobile>
                <FormColumn title="Date of loss">
                  <DateOfBirthInput
                    onChange={(day, month, year) => {
                      onChangePriorLoss(
                        priorLoss.lossIdentifier,
                        'dateOfLoss',
                        year + '-' + month + '-' + day
                      );
                    }}
                    initialValue={
                      hasDateOfLoss
                        ? [
                            dateOfLoss.getUTCDate().toString(),
                            (dateOfLoss.getUTCMonth() + 1).toString(),
                            dateOfLoss.getUTCFullYear().toString()
                          ]
                        : undefined
                    }
                    error={
                      !inputChanged &&
                      validationError?.Field === validationField &&
                      validationError?.SubField === 'DateOfLoss'
                        ? validationError?.Message
                        : undefined
                    }
                  />
                </FormColumn>
                <FormColumn title="Loss Type">
                  <Select
                    options={[
                      { displayValue: '', value: '' },
                      { displayValue: 'Risk - Full', value: 'Risk - Full' },
                      { displayValue: 'Risk - Limited', value: 'Risk - Limited' },
                      { displayValue: 'Subject', value: 'Subject' }
                    ]}
                    onChange={(value) => {
                      onChangePriorLoss(priorLoss.lossIdentifier, 'lossType', value);
                    }}
                    error={
                      !inputChanged &&
                      validationError?.SubField === 'LossType' &&
                      validationError?.Message
                    }
                    value={priorLoss.lossType}
                  />
                </FormColumn>
              </FormRow>
              <FormRow breakMobile>
                <FormColumn title="Date Paid">
                  <DateOfBirthInput
                    onChange={(day, month, year) => {
                      onChangePriorLoss(
                        priorLoss.lossIdentifier,
                        'lossPaidDate',
                        year + '-' + month + '-' + day
                      );
                    }}
                    initialValue={
                      hasPaidDate
                        ? [
                            paidDate.getUTCDate().toString(),
                            (paidDate.getUTCMonth() + 1).toString(),
                            paidDate.getUTCFullYear().toString()
                          ]
                        : undefined
                    }
                    error={
                      !inputChanged &&
                      validationError?.Field === validationField &&
                      validationError?.SubField === 'LossPaidDate'
                        ? validationError?.Message
                        : undefined
                    }
                  />
                </FormColumn>
                <FormColumn title="Amount">
                  <TextInput
                    currency={true}
                    error={
                      !inputChanged &&
                      validationError?.SubField === 'LossPaidAmount' &&
                      validationError?.Message
                    }
                    onChange={(e) => {
                      const value = e.currentTarget.value;
                      onChangePriorLoss(
                        priorLoss.lossIdentifier,
                        'lossPaidAmount',
                        parseFloat(value.replace(/[^.0-9]/, ''))
                      );
                    }}
                    value={priorLoss.lossPaidAmount || ''}
                    inputMode="decimal"
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn title="Cause of Loss">
                  <Select
                    options={[
                      { displayValue: '', value: '' },
                      { displayValue: 'Mysterious Disappearance', value: 'DISSC' },
                      { displayValue: 'Fire', value: 'FIRE' },
                      { displayValue: 'All Other', value: 'OTHER' },
                      { displayValue: 'Physical damage (all other)', value: 'PHYDA' },
                      { displayValue: 'Earthquake', value: 'QUAKE' },
                      { displayValue: 'Theft', value: 'THEFT' },
                      { displayValue: 'Water Damage', value: 'WATER' }
                    ]}
                    onChange={(value) => {
                      onChangePriorLoss(priorLoss.lossIdentifier, 'causeOfLossCode', value);
                    }}
                    error={
                      !inputChanged &&
                      validationError?.SubField === 'CauseOfLossCode' &&
                      validationError?.Message
                    }
                    value={priorLoss.causeOfLossCode}
                  />
                </FormColumn>
                <FormColumn title="Loss Status" style={{ maxWidth: 'calc(50% - 8px)' }}>
                  <Select
                    options={[
                      { displayValue: '', value: '' },
                      { displayValue: 'Closed', value: 'Closed' },
                      { displayValue: 'Open', value: 'Open' },
                      { displayValue: 'Not Covered', value: 'Not Covered' },
                      { displayValue: 'Claim Withdrawn', value: 'Claim Withdrawn' }
                    ]}
                    onChange={(value) => {
                      onChangePriorLoss(priorLoss.lossIdentifier, 'lossStatus', value);
                    }}
                    error={
                      !inputChanged &&
                      validationError?.SubField === 'LossStatus' &&
                      validationError?.Message
                    }
                    value={priorLoss.lossStatus}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn title="Description" label="Loss Description">
                  <TextAreaInput
                    error={
                      !inputChanged &&
                      validationError?.Field === validationField &&
                      validationError?.SubField === 'CauseOfLossDescription'
                        ? validationError?.Message
                        : undefined
                    }
                    value={priorLoss.causeOfLossDescription}
                    onChange={(e) => {
                      const value = e.currentTarget.value;
                      onChangePriorLoss(priorLoss.lossIdentifier, 'causeOfLossDescription', value);
                    }}
                    style={{ resize: 'none', maxWidth: '100%' }}
                    rows={4}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <RequirementItemContainer
                  style={{ padding: '16px 0px 0px 0px', cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onChangePriorLoss(
                      priorLoss.lossIdentifier,
                      'lossCatastrophe',
                      !priorLoss.lossCatastrophe
                    );
                  }}
                >
                  <RequirementCheckboxContainer>
                    <Checkbox
                      label="This is a catastrophic loss."
                      checked={priorLoss.lossCatastrophe}
                      onChange={() => {
                        onChangePriorLoss(
                          priorLoss.lossIdentifier,
                          'lossCatastrophe',
                          !priorLoss.lossCatastrophe
                        );
                      }}
                    />
                  </RequirementCheckboxContainer>
                  <RequirementContentContainer>
                    <RequirementTitle>This loss was due to a catastrophe.</RequirementTitle>
                    <RequirementDescription>
                      Check this box if this is a catastrphic loss i.e. lost due to a natural
                      disaster.
                    </RequirementDescription>
                  </RequirementContentContainer>
                </RequirementItemContainer>
              </FormRow>
              <Button
                leftIcon={<IoCloseOutline />}
                onClick={(e) => {
                  e.preventDefault();
                  onChange('PriorLosses', [
                    ...criteria.PriorLosses.filter(
                      (s) => s.lossIdentifier !== priorLoss.lossIdentifier
                    )
                  ]);
                }}
              >
                Remove this loss
              </Button>
            </div>
          );
        })}
      {criteria.PriorLosses.length < 20 && (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '16px' }}>
          <Button
            leftIcon={<IoAddOutline />}
            onClick={(e) => {
              e.preventDefault();
              onChange('PriorLosses', [
                ...criteria.PriorLosses,
                { lossIdentifier: 'loss_' + new Date().getTime().toString() } // Generate temporary ID
              ]);
            }}
          >
            Add a loss
          </Button>
        </div>
      )}
      <div style={{ padding: '0px 0px 16px 0px' }}>
        <p style={{ color: '#999999', fontSize: '0.8em' }}>
          If you are a California Resident, please read our{' '}
          <a
            target="_blank"
            href="https://www.chubb.com/us-en/online-privacy-policy.html#CCPA-section"
          >
            Notice at Collection
          </a>
          .
        </p>
      </div>
      <PageSection noBorder noPadding centered>
        <ButtonContainer center>
          {props.onBack && (
            <Button leftIcon={<IoArrowBack />} onClick={props.onBack}>
              Back
            </Button>
          )}
          <Button
            loading={props.loading}
            onClick={(e) => {
              e.preventDefault();
              props.onNext();
            }}
            icon={<IoArrowForward />}
            primary
          >
            Continue
          </Button>
        </ButtonContainer>
      </PageSection>
    </FormContainer>
  );
};

export const LocationPage: React.FunctionComponent<React.PropsWithChildren<PageProps>> = (
  props
) => (
  <PageSection>
    <h1>Jewelry storage</h1>
    <p>Tell us a little bit about where and how you'll store this jewelry.</p>
    <JewelryLocationPage {...props} />
  </PageSection>
);

const JewelryLocationPage = (props: PageProps) => {
  const [inputChanged, setInputChanged] = React.useState(false);
  const [fraudDisclosure, setFraudDisclosure] = React.useState<string>();
  const criteria = props.policy.Underwriting.Criteria as ChubbJewelryCriteria;
  const [state, setState] = React.useState<string>(criteria.StorageAddress?.stateOrProvinceName);

  React.useEffect(() => {
    if (!props.loading) {
      setInputChanged(false);
    }
  }, [props.loading]);

  React.useEffect(() => {
    const text = FraudDisclosuresByState[state?.toUpperCase()]?.text;
    if (text) {
      setFraudDisclosure(text);
    }
  }, [state]);

  const onChange = <T,>(k: string, v: T) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.policy,
      Underwriting: {
        ...props.policy.Underwriting,
        Criteria: {
          ...criteria,
          [k]: v
        }
      }
    });
  };

  const onChangeMultiStorageAddress = <T,>(vals: [string, T][]) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.policy,
      Underwriting: {
        ...props.policy.Underwriting,
        Criteria: {
          ...criteria,
          StorageAddress: {
            ...criteria.StorageAddress,
            ...Object.fromEntries(vals)
          }
        }
      }
    });
  };

  const onChangeMultiInsured = <T,>(vals: [string, T][]) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.policy,
      Underwriting: {
        ...props.policy.Underwriting,
        Insured: {
          ...props.policy.Underwriting.Insured,
          ...Object.fromEntries(vals)
        }
      }
    });
  };

  return (
    <FormContainer
      onSubmit={(e) => {
        e.preventDefault();
        props.onNext();
      }}
    >
      <FormRowHeader
        title="Storage address"
        description="Enter the address that this jewelry will be stored at. For gifts, please enter the recipient's address."
      />
      <AddressInputForm
        showSecondLine
        singleSecondLine
        onChange={(address) => {
          onChangeMultiStorageAddress([
            ['Line1', address.streetAddress || ''],
            ['Line2', address.streetAddressLine2 || ''],
            ['City', address.city || ''],
            ['StateOrProvinceName', address.state || ''],
            ['PostalCode', address.zipCode || '']
          ]);
          setState(address.state || '');
        }}
        initialValue={{
          streetAddress: criteria.StorageAddress?.line1 || '',
          streetAddressLine2: criteria.StorageAddress?.line2 || '',
          city: criteria.StorageAddress?.city || '',
          state: criteria.StorageAddress?.stateOrProvinceName || '',
          zipCode: criteria.StorageAddress?.postalCode || ''
        }}
        validationError={{
          streetAddress:
            !inputChanged &&
            props.validationError?.SubField === 'Line1' &&
            props.validationError?.Message,
          streetAddressLine2:
            !inputChanged &&
            props.validationError?.SubField === 'Line2' &&
            props.validationError?.Message,
          city:
            !inputChanged &&
            props.validationError?.SubField === 'City' &&
            props.validationError?.Message,
          state:
            !inputChanged &&
            props.validationError?.SubField === 'StateOrProvinceName' &&
            props.validationError?.Message,
          zipCode:
            !inputChanged &&
            props.validationError?.SubField === 'PostalCode' &&
            props.validationError?.Message
        }}
      />
      <FormRow>
        <RequirementItemContainer
          style={{ padding: '16px 0px 0px 0px', cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            onChange('StorageIsResidential', !criteria.StorageIsResidential);
          }}
        >
          <RequirementCheckboxContainer>
            <Checkbox
              label="This is also my residential address"
              checked={criteria.StorageIsResidential}
              onChange={() => {
                onChange('StorageIsResidential', !criteria.StorageIsResidential);
              }}
            />
          </RequirementCheckboxContainer>
          <RequirementContentContainer>
            <RequirementTitle>This is also my residential address</RequirementTitle>
            <RequirementDescription>
              Check this box if you also live at this address.
            </RequirementDescription>
          </RequirementContentContainer>
        </RequirementItemContainer>
      </FormRow>
      {!criteria.StorageIsResidential && (
        <>
          <FormRowHeader
            title="Residential address"
            description="Enter the address that you live at."
          />
          <AddressInputForm
            showSecondLine
            singleSecondLine
            onChange={(address) => {
              onChangeMultiInsured([
                ['AddressLine1', address.streetAddress],
                ['AddressLine2', address.aptFloor],
                ['AddressCity', address.city],
                ['AddressState', address.state],
                ['AddressZipCode', address.zipCode]
              ]);
            }}
            initialValue={{
              streetAddress: props.policy.Underwriting.Insured.AddressLine1,
              streetAddressLine2: props.policy.Underwriting.Insured.AddressLine2,
              city: props.policy.Underwriting.Insured.AddressCity,
              state: props.policy.Underwriting.Insured.AddressState,
              zipCode: props.policy.Underwriting.Insured.AddressZipCode
            }}
            validationError={{
              streetAddress:
                !inputChanged &&
                props.validationError?.SubField === 'AddressLine1' &&
                props.validationError?.Message,
              streetAddressLine2:
                !inputChanged &&
                props.validationError?.SubField === 'AddressLine2' &&
                props.validationError?.Message,
              city:
                !inputChanged &&
                props.validationError?.SubField === 'AddressCity' &&
                props.validationError?.Message,
              state:
                !inputChanged &&
                props.validationError?.SubField === 'AddressState' &&
                props.validationError?.Message,
              zipCode:
                !inputChanged &&
                props.validationError?.SubField === 'AddressZipCode' &&
                props.validationError?.Message
            }}
          />
        </>
      )}
      <FormRowHeader
        title="Construction type"
        description="What material is the jewelry storage location made of?"
      />
      <FormRow>
        <FormColumn>
          <Select
            options={[
              { displayValue: '', value: '' },
              { displayValue: 'Frame', value: 'Frame' },
              { displayValue: 'Fire Resistant', value: 'FR/WR' },
              { displayValue: 'EIFS/Masonry', value: 'EIFS/Masonry' }
            ]}
            style={{ maxWidth: '200px' }}
            onChange={(value) => onChange('ConstructionType', value)}
            error={
              !inputChanged &&
              props.validationError?.Field === 'ConstructionType' &&
              props.validationError?.Message
            }
            value={
              (props.policy.Underwriting.Criteria as ChubbJewelryCriteria).ConstructionType || ''
            }
          />
        </FormColumn>
      </FormRow>
      <FormRowHeader
        title="Residence type"
        description="Type of residence where jewelry is stored?"
      />
      <FormRow>
        <FormColumn>
          <Select
            options={[
              { displayValue: '', value: '' },
              { displayValue: 'House', value: 'House' },
              { displayValue: 'Condominium', value: 'Condominium' },
              { displayValue: 'Cooperative', value: 'Cooperative' },
              { displayValue: 'Renters', value: 'Renters' }
            ]}
            style={{ maxWidth: '200px' }}
            onChange={(value) => onChange('ResidenceType', value)}
            error={
              !inputChanged &&
              props.validationError?.Field === 'ResidenceType' &&
              props.validationError?.Message
            }
            value={(props.policy.Underwriting.Criteria as ChubbJewelryCriteria).ResidenceType || ''}
          />
        </FormColumn>
      </FormRow>
      {requiresAUI(props.policy) && (
        <>
          <FormRow alignItems={'flex-end'}>
            <FormRowHeader title="Do you have any domestic employees?" />
            <ToggleSwitch
              enabled={criteria.HasInHouseEmployees}
              onChange={() => {
                onChange('HasInHouseEmployees', !criteria.HasInHouseEmployees);
              }}
            />
          </FormRow>
          {criteria.HasInHouseEmployees && (
            <FormRow>
              <FormColumn title="Please list the number of employees, their employment status (full or part-time) and whether any of the employees live at your residence.">
                <TextAreaInput
                  error={
                    props.validationError?.Field === 'InHouseEmployees' &&
                    props.validationError?.Message
                  }
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    onChange('InHouseEmployees', value);
                  }}
                  value={criteria.InHouseEmployees}
                  style={{ resize: 'none', width: '100%' }}
                  rows={5}
                />
              </FormColumn>
            </FormRow>
          )}
        </>
      )}
      <FormRowHeader
        title="Select any statements that apply."
        description="Select the items below that most accurately describe the jewelry storage location."
      />
      <FormRow>
        <FormColumn>
          <RequirementItemContainer
            style={{ padding: '0px', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              onChange(
                'IsStoredInClimateControlledStorage',
                !criteria.IsStoredInClimateControlledStorage
              );
            }}
          >
            <RequirementCheckboxContainer>
              <Checkbox
                label="IsStoredInClimateControlledStorage"
                checked={!!criteria.IsStoredInClimateControlledStorage}
                onChange={() =>
                  onChange(
                    'IsStoredInClimateControlledStorage',
                    !criteria.IsStoredInClimateControlledStorage
                  )
                }
              />
            </RequirementCheckboxContainer>
            <RequirementContentContainer>
              <RequirementTitle>Item is stored in climate controlled storage.</RequirementTitle>
            </RequirementContentContainer>
          </RequirementItemContainer>
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn>
          <RequirementItemContainer
            style={{ padding: '0px', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              onChange('IsCoveredItemsElevated', !criteria.IsCoveredItemsElevated);
            }}
          >
            <RequirementCheckboxContainer>
              <Checkbox
                label="IsCoveredItemsElevated"
                checked={!!criteria.IsCoveredItemsElevated}
                onChange={() =>
                  onChange('IsCoveredItemsElevated', !criteria.IsCoveredItemsElevated)
                }
              />
            </RequirementCheckboxContainer>
            <RequirementContentContainer>
              <RequirementTitle>Items are elevated.</RequirementTitle>
            </RequirementContentContainer>
          </RequirementItemContainer>
        </FormColumn>
      </FormRow>
      {requiresAUI(props.policy) &&
        [
          {
            criteria: criteria.CentralStationFireAlarm,
            field: 'CentralStationFireAlarm',
            title: 'Central station firm alarm',
            description: 'This location has a central station fire alarm.'
          },
          {
            criteria: criteria.SignalContinuity,
            field: 'SignalContinuity',
            title: '24 hour signal continuity',
            description:
              'This location has 24 hour signal continuity protection for central station, direct fire and burglar alarm systems, which activates the alarm when interrupted, is installed in a residence location.'
          },
          {
            criteria: criteria.TwentyFourHourSecurity,
            field: 'TwentyFourHourSecurity',
            title: '24 hour security',
            description:
              'Security protection for the entire external perimeter of a home consisting of one or more of the following - Closed circuit TV cameras monitored 24 hours a day, detection system, external to the residence which is motion activated and monitored 24 hours per day, 24 hour on site security guard.'
          },
          {
            criteria: criteria.FullTimeCaretaker,
            field: 'FullTimeCaretaker',
            title: 'Full time caretaker',
            description: 'A full time caretaker lives at the residence location year round.'
          },
          {
            criteria: criteria.GatedCommunity,
            field: 'GatedCommunity',
            title: 'Gated community',
            description: 'This residence location is located in a gated community.'
          },
          {
            criteria: criteria.GatedCommunityPatrol,
            field: 'GatedCommunityPatrol',
            title: 'Gated community patrol service',
            description:
              "This residence location in a gated community has a fire and burglar alarm that when activated, alerts the community's 24-hour patrol service to dispatch a guard to a residence location."
          },
          {
            criteria: criteria.FiftyPercentJewelryKeptInVault,
            field: 'FiftyPercentJewelryKeptInVault',
            title: 'Bank vault',
            description: 'Do you regularly use a bank vault for at least 50% of your jewelry?'
          },
          {
            criteria: criteria.SafeAtHome,
            field: 'SafeAtHome',
            title: 'Safe at home',
            description: 'A home safe is installed at residence location.'
          }
        ].map((option) => (
          <FormRow key={option.field}>
            <FormColumn>
              <RequirementItemContainer
                style={{ padding: '0px', cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(option.field, !option.criteria);
                }}
              >
                <RequirementCheckboxContainer>
                  <Checkbox
                    label={option.field}
                    checked={!!option.criteria}
                    onChange={() => onChange(option.field, !option.criteria)}
                  />
                </RequirementCheckboxContainer>
                <RequirementContentContainer>
                  <RequirementTitle>{option.title}</RequirementTitle>
                  <RequirementDescription>{option.description}</RequirementDescription>
                </RequirementContentContainer>
              </RequirementItemContainer>
            </FormColumn>
          </FormRow>
        ))}
      <div style={{ padding: '0px 0px 0px 0px' }}>
        <p style={{ color: '#999999', fontSize: '1em' }}>Consumer Disclosure Statement</p>
        <p style={{ color: '#999999', fontSize: '0.8em' }}>
          Please read and agree to the following
        </p>
        <p style={{ color: '#999999', fontSize: '0.8em' }}>
          To provide an accurate quote, we will obtain information about you and any household
          member from consumer reporting agencies and other third parties where permitted by law. In
          connection with this application for insurance, we will review your credit report or use a
          credit-based insurance score on the information contained in your credit report (in
          applicable states), driving and claim histories and other consumer reports. We will use
          this information to undewrite and/or rate your policy.
        </p>
      </div>
      {fraudDisclosure && (
        <div style={{ padding: '0px 0px 0px 0px' }}>
          <p style={{ color: '#999999', fontSize: '1em' }}>Fraud Disclosures</p>
          <p style={{ color: '#999999', fontSize: '0.8em' }}>{fraudDisclosure}</p>
        </div>
      )}
      <RequirementItemContainer
        style={{ padding: '0px', cursor: 'pointer' }}
        onClick={(e) => {
          e.stopPropagation();
          onChange('AcceptConsumerDisclosure', !criteria.AcceptConsumerDisclosure);
        }}
      >
        <RequirementCheckboxContainer>
          <Checkbox
            label={'ElectronincCommunicationsConsent'}
            checked={!!criteria.AcceptConsumerDisclosure}
            onChange={() => {
              onChange('AcceptConsumerDisclosure', !criteria.AcceptConsumerDisclosure);
            }}
          />
        </RequirementCheckboxContainer>
        <RequirementContentContainer>
          <RequirementDescription marginTop="0px">
            I acknowledge that I've read and agreed to the Consumer Disclosure Statement
          </RequirementDescription>
        </RequirementContentContainer>
      </RequirementItemContainer>
      {props.validationError?.Field == 'AcceptConsumerDisclosure' &&
        !criteria.AcceptConsumerDisclosure && (
          <ErrorDisplay style={{ paddingBottom: '16px' }}>
            {props.validationError?.Message}
          </ErrorDisplay>
        )}
      <PageSection noBorder noPadding centered>
        <ButtonContainer center>
          {props.onBack && (
            <Button leftIcon={<IoArrowBack />} onClick={props.onBack}>
              Back
            </Button>
          )}
          <Button
            loading={props.loading}
            onClick={(e) => {
              e.preventDefault();
              props.onNext();
            }}
            icon={<IoArrowForward />}
            primary
          >
            Continue
          </Button>
        </ButtonContainer>
      </PageSection>
    </FormContainer>
  );
};

const pages: Array<Page> = [
  {
    path: '/app/jewelry/:id?',
    render: (props) => (
      <GetPolicyWrapper policyId={props.match.params.id}>
        {(policy) => <CollectJewelryInfoPage policy={policy} />}
      </GetPolicyWrapper>
    ),
    hasError: (validationError: ValidationError) =>
      JewelryProductValidationKeys.indexOf(validationError.Field as keyof JewelryProduct) > -1,
    icon: <IoPricetagsOutline />
  },
  {
    path: '/app/jewelry/v2/:id/criteria',
    render: (props) => (
      <GetPolicyWrapper policyId={props.match.params.id}>
        {(policy) => <PageWrapper policy={policy} pages={pages} component={CriteriaPage} />}
      </GetPolicyWrapper>
    ),
    hasError: (validationError: ValidationError) =>
      validationError.Field.startsWith('PriorLosses') ||
      [
        'ZipCode',
        'OccupationDetails',
        'OtherOccupationDetails',
        'AdditionalInsuredFirstName',
        'AdditionalInsuredLastName',
        'AdditionalInsuredOccupation',
        'AdditionalInsuredOccupationDetails',
        'InsuredTravel',
        'AverageJewelryValueOnTravel',
        'JewelrySafeguardOnTravel',
        'JewelrySafeguardAtHomeInsuredOnTravel'
      ].includes(validationError.Field) ||
      !![
        'FirstName',
        'LastName',
        'Phone',
        'Occupation',
        'Email',
        'DateOfBirth',
        'DateOfLoss',
        'LossType',
        'LossPaidDate',
        'LossPaidAmount',
        'CauseOfLossCode',
        'CauseOfLossDescription',
        'LossStatus'
      ].find((subfield) => validationError.SubField === subfield),
    icon: <IoPersonOutline />
  },
  {
    path: '/app/jewelry/v2/:id/location',
    render: (props) => (
      <GetPolicyWrapper policyId={props.match.params.id}>
        {(policy) => <PageWrapper policy={policy} pages={pages} component={LocationPage} />}
      </GetPolicyWrapper>
    ),
    hasError: (validationError: ValidationError) =>
      !![
        'Storage',
        'ConstructionType',
        'Residence',
        'InHouseEmployees',
        'AcceptConsumerDisclosure'
      ].find((prefix) => validationError.Field.startsWith(prefix)) ||
      !!validationError?.SubField?.startsWith('Address'),
    icon: <IoHomeOutline />
  },
  {
    path: '/app/jewelry/v2/:id/coverage',
    render: (props) => (
      <GetPolicyWrapper policyId={props.match.params.id}>
        {(policy) => (
          <PageWrapper
            policy={policy}
            pages={pages}
            component={getCoveragePage(
              'Review your coverage',
              'Please review your coverage selections below. You can add additional coverages to your policy.',
              () => sdkValidatePolicy(policy.ID),
              true,
              'Jewelry Insurance provided as part of a Valuable Articles Policy underwritten by Chubb.'
            )}
          />
        )}
      </GetPolicyWrapper>
    ),
    hasError: () => false,
    icon: <IoDocumentTextOutline />
  },
  {
    path: '/app/jewelry/v2/:id/payment',
    render: (props) => (
      <ExistingUserWrapper>
        {(account) => (
          <GetPolicyWrapper policyId={props.match.params.id}>
            {(policy) => (
              <PageWrapper
                policy={policy}
                pages={pages}
                component={getPaymentPage(
                  () =>
                    account ? userGetPaymentSession(policy.ID) : sdkGetPaymentSession(policy.ID),
                  window.location.origin + `/policy/${policy.ID}/complete?flow=jewelry`,
                  account
                )}
              />
            )}
          </GetPolicyWrapper>
        )}
      </ExistingUserWrapper>
    ),
    hasError: (validationError: ValidationError) => validationError.Field === 'Insured',
    icon: <IoBagCheckOutline />
  }
];

export default (): JSX.Element => {
  // Side effect to trigger re-renders when the page changes
  useRouteMatch();
  const history = useHistory();

  const current = pages.findIndex((page) =>
    matchPath(window.location.pathname, {
      path: page.path,
      exact: true
    })
  );

  React.useEffect(() => {
    apm().sendEvent('oyster.d2c.flow_page_change', {
      flow_type: 'jewelry',
      page_index: current,
      route: pages[current]?.path,
      path: window.location.pathname,
      query: window.location.search
    });
  }, [current]);

  return (
    <PageContainer width={700} policyType={PolicyType.chubbJewelry}>
      <PageSection centered>
        <OysterLogo scale={1.5} inline />
      </PageSection>
      <ErrorBoundary forceMobile>
        {current >= 0 && (
          <PageSection noBorder noPadding>
            <Progress
              steps={pages.map((page, i) =>
                i < current ? (
                  <LinkComponent
                    href={(() => {
                      const match = /\/(pol_.+)\//.exec(history.location.pathname);
                      if (match && match[1]) {
                        return pages[i].path.replace(':id', match[1]);
                      }
                      return pages[i].path;
                    })()}
                  >
                    {page.icon}
                  </LinkComponent>
                ) : (
                  page.icon
                )
              )}
              currentStep={current}
            />
          </PageSection>
        )}
        <Switch>
          <Route
            exact
            path="/app/jewelry/v2/ineligible"
            render={() => (
              <PageSection>
                <h1>Sorry!</h1>
                <p>
                  Thank you for your interest. Unfortunately your property is not eligible for
                  coverage under our program.
                </p>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <img width={260} height={254} src="/images/underwriting_error.svg" alt="" />
                </div>
              </PageSection>
            )}
          ></Route>
          {pages.map((page) => (
            <Route exact key={page.path} path={page.path} render={page.render} />
          ))}
          <Redirect to="/app/jewelry" />
        </Switch>
      </ErrorBoundary>
    </PageContainer>
  );
};
