export const FraudDisclosuresByState = {
  AL: {
    text: 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or who knowingly presents false information in an application for insurance is guilty of a crime and may be subject to restitution, fines, or confinement in prison, or any combination thereof.'
  },
  AK: {
    text: 'A person who knowingly and with intent to injure, defraud, or deceive an insurance company files a claim containing false, incomplete, or misleading information may be prosecuted under state law.'
  },
  AS: {
    text: ''
  },
  AZ: {
    text: 'For your protection Arizona law requires the following statement to appear on this form. Any person who knowingly presents a false or fraudulent claim for payment of a loss is subject to criminal and civil penalties.'
  },
  AR: {
    text: 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.'
  },
  CA: {
    text: 'For your protection California law requires the following to appear on this form.  Any person who knowingly presents false or fraudulent information to obtain or amend insurance coverage or to make a claim for the payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison.'
  },
  CO: {
    text: 'It is unlawful to knowingly provide false, incomplete, or misleading facts or information to an insurance company for the purpose of defrauding or attempting to defraud the company.  Penalties may include imprisonment, fines, denial of insurance, and civil damages.  Any insurance company or agent of an insurance company who knowingly provides false, incomplete, or misleading facts or information to a policyholder or claimant for the purpose of defrauding or attempting to defraud the policyholder or claimant with regard to a settlement or award payable from insurance proceeds shall be reported to the Colorado division of insurance within the department of regulatory agencies.'
  },
  CT: {
    text: ''
  },
  DE: {
    text: 'Any person who knowingly, and with intent to injure, defraud or deceive any insurer, files a statement of claim containing any false, incomplete or misleading information is guilty of a felony.'
  },
  DC: {
    text: 'WARNING: It is a crime to provide false or misleading information to an insurer for the purpose of defrauding the insurer or any other person. Penalties include imprisonment and/or fines. In addition, an insurer may deny insurance benefits if false information materially related to a claim was provided by the applicant.'
  },
  FM: {
    text: ''
  },
  FL: {
    text: 'Any person who knowingly and with intent to injure, defraud, or deceive any insurer files a statement of claim or an application containing any false, incomplete, or misleading information is guilty of a felony of the third degree.'
  },
  GA: {
    text: ''
  },
  GU: {
    text: ''
  },
  HI: {
    text: ''
  },
  ID: {
    text: 'Any person who knowingly, and with intent to defraud or deceive any insurance company, files a statement of claim containing any false, incomplete, or misleading information is guilty of a felony. The lack of such a statement shall not constitute a defense against prosecution under this section.'
  },
  IL: {
    text: ''
  },
  IN: {
    text: 'A person who knowingly and with intent to defraud an insurer files a statement of claim containing any false, incomplete, or misleading information commits a felony.'
  },
  IA: {
    text: ''
  },
  KS: {
    text: ''
  },
  KY: {
    text: '1.  Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance containing any materially false information or conceals, for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime. 2.  Any person who knowingly and with intent to defraud any insurance company or other person files a statement of claim containing any materially false information or conceals, for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime.'
  },
  LA: {
    text: 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.'
  },
  ME: {
    text: 'It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties may include imprisonment, fines or a denial of insurance benefits.'
  },
  MH: {
    text: ''
  },
  MD: {
    text: 'Any person who knowingly or willfully presents a false or fraudulent claim for payment of a loss or benefit or who knowingly or willfully presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.'
  },
  MA: {
    text: ''
  },
  MI: {
    text: ''
  },
  MN: {
    text: 'A person who files a claim with intent to defraud or helps commit a fraud against an insurer is guilty of a crime.'
  },
  MS: {
    text: ''
  },
  MO: {
    text: ''
  },
  MT: {
    text: ''
  },
  NE: {
    text: ''
  },
  NV: {
    text: ''
  },
  NH: {
    text: 'Any person who, with a purpose to injure, defraud, or deceive any insurance company, files a statement of claim containing any false, incomplete, or misleading information is subject to prosecution and punishment for insurance fraud, as provided in RSA 638:20.'
  },
  NJ: {
    text: 'Any person who includes any false or misleading information on an application for an insurance policy is subject to criminal and civil penalties.'
  },
  NM: {
    text: 'ANY PERSON WHO KNOWINGLY PRESENTS A FALSE OR FRAUDULENT CLAIM FOR PAYMENT OF A LOSS OR BENEFIT OR KNOWINGLY PRESENTS FALSE INFORMATION IN AN APPLICATION FOR INSURANCE IS GUILTY OF A CRIME AND MAY BE SUBJECT TO CIVIL FINES AND CRIMINAL PENALTIES.'
  },
  NY: {
    text: 'Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information, or conceals for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which is a crime, and shall also be subject to a civil penalty not to exceed five thousand dollars and the stated value of the claim for each such violation'
  },
  NC: {
    text: ''
  },
  ND: {
    text: ''
  },
  MP: {
    text: ''
  },
  OH: {
    text: 'Any person who, with intent to defraud or knowing that he is facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement is guilty of insurance fraud.'
  },
  OK: {
    text: 'WARNING: Any person who knowingly, and with intent to injure, defraud or deceive any insurer, makes any claim for the proceeds of an insurance policy containing any false, incomplete or misleading information is guilty of a felony.'
  },
  OR: {
    text: ''
  },
  PW: {
    text: ''
  },
  PA: {
    text: 'Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information or conceals for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime and subjects such person to criminal and civil penalties.'
  },
  PR: {
    text: ''
  },
  RI: {
    text: 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.'
  },
  SC: {
    text: ''
  },
  SD: {
    text: ''
  },
  TN: {
    text: 'It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.'
  },
  TX: {
    text: 'Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information or conceals for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act.'
  },
  UT: {
    text: ''
  },
  VT: {
    text: ''
  },
  VI: {
    text: ''
  },
  VA: {
    text: 'It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits'
  },
  WA: {
    text: 'It is a crime to knowingly provide false, incomplete, or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines, and denial of insurance benefits.'
  },
  WV: {
    text: 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison'
  },
  WI: {
    text: ''
  },
  WY: {
    text: ''
  }
};
