import * as React from 'react';

import styled from 'styled-components';
import { IoCheckmarkSharp } from 'react-icons/io5';

const CoverageItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: calc(50% - 5px);

  @media (max-width: 520px) {
    max-width: 100%;
  }
`;

const CoverageItemCheckboxContainer = styled.div`
  padding-right: 5px;
`;

const CoverageItemDetailsContainer = styled.div`
  flex-direction: column;
`;

const CoverageItemTitle = styled.div`
  font-weight: 600;
  padding-bottom: 5px;
`;

const CoverageItemDescription = styled.div`
  color: #999999;
  font-size: 0.8em;
`;

const CoverageItemCheckbox = styled.div`
  padding-right: 5px;
  color: #0ea5e9;
  font-size: 1.5em;
`;

export const CoverageItem: React.FunctionComponent<
  React.PropsWithChildren<{
    title: string;
    description: string;
    icon?: JSX.Element;
    iconColor?: string;
  }>
> = (props) => (
  <CoverageItemContainer className="coverage-page-section-coverage-item--container">
    <CoverageItemCheckboxContainer>
      <CoverageItemCheckbox>
        {props.icon || <IoCheckmarkSharp style={{ color: props.iconColor || '#0EA5E9' }} />}
      </CoverageItemCheckbox>
    </CoverageItemCheckboxContainer>
    <CoverageItemDetailsContainer>
      <CoverageItemTitle>{props.title}</CoverageItemTitle>
      <CoverageItemDescription
        style={{ color: props.iconColor }}
        className="coverage-page-section-coverage-item--description"
      >
        {props.description}
      </CoverageItemDescription>
    </CoverageItemDetailsContainer>
  </CoverageItemContainer>
);

const CoveragePageSectionContainer = styled.div`
  padding: 18px 0px;
  flex: 1 1 0;
`;

const CoveragePageSectionTitle = styled.div<{ textColor?: string }>`
  padding: 0px 0px 18px 0px;
  width: 100%;

  span {
    font-weight: 600;
    font-size: 0.8em;
    color: ${(props) => props.textColor || '#999999'};
    text-transform: uppercase;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 0.5em;

    &::after {
      content: '';
      background-color: ${(props) => props.textColor || '#999999'};
      flex-grow: 1;
      height: 1px;
    }
  }
`;

const CoveragePageSectionContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px 10px;
`;

export const CoveragePageSection: React.FunctionComponent<
  React.PropsWithChildren<{ title?: string; color?: string }>
> = (props) => (
  <CoveragePageSectionContainer>
    {props.title && (
      <CoveragePageSectionTitle textColor={props.color}>
        <span className="coverage-page-section--title">{props.title}</span>
      </CoveragePageSectionTitle>
    )}
    <CoveragePageSectionContent>{props.children}</CoveragePageSectionContent>
  </CoveragePageSectionContainer>
);
