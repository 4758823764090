import * as React from 'react';
import styled from 'styled-components';

import { BikeProduct, Policy, Product, ProductType, ValidationError } from '@oysterjs/types';
import { PageSection } from '@oysterjs/ui/Page';
import {
  IoCheckmarkCircle,
  IoChevronDown,
  IoChevronForward,
  IoArrowForward,
  IoPencil,
  IoDuplicate,
  IoTrash
} from 'react-icons/io5';
import { Button, ButtonContainer } from '@oysterjs/ui/Button';
import { ErrorType, WrappedError } from '@oysterjs/core/errors';

interface ProductInfoPageProps<T> {
  title?: string;
  description?: string;
  initialFormData: T;
  component: React.FunctionComponent<{
    loading?: boolean;
    validationError?: ValidationError;
    formData: T;
    setData: (field: string, fn: (data: T) => T) => void;
    onContinue: (product: Product) => void;
  }>;
  hasError: (validationError: ValidationError) => boolean;
  onSubmit: (
    product: Product
  ) => Promise<{ Policy: Policy; NextValidationError?: ValidationError }>;
  onContinue: (policy?: Policy) => void;
}

export const ProductInfoPage = <T,>(props: ProductInfoPageProps<T>): JSX.Element => {
  const [validationError, setValidationError] = React.useState<ValidationError>();
  const [formData, setFormData] = React.useState<T>(props.initialFormData);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error>();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onContinue = (product: Product) => {
    setLoading(true);
    setTimeout(
      () =>
        props
          .onSubmit(product)
          .then(({ Policy, NextValidationError }) => {
            if (NextValidationError) {
              if (props.hasError(NextValidationError)) {
                setValidationError(NextValidationError);
                return;
              }
            }
            props.onContinue(Policy);
          })
          .catch((e) => {
            const err = WrappedError.asWrappedError(e);
            if (err.type?.() === ErrorType.validationError) {
              setValidationError(err.getValidationError());
            } else {
              setError(e);
            }
          })
          .finally(() => setLoading(false)),
      500
    );
  };

  React.useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  React.useEffect(() => {
    if (props.initialFormData) {
      setFormData(props.initialFormData);
    }
  }, [JSON.stringify(props.initialFormData)]);

  const setData = (field: string, fn: (prev: T) => T) => {
    if (validationError?.Field === field) {
      setValidationError(undefined);
    }
    setFormData(fn);
  };

  return props.title && props.description ? (
    <PageSection>
      <h1>{props.title}</h1>
      <p>{props.description}</p>
      {React.createElement(props.component, {
        formData,
        setData,
        onContinue,
        loading,
        validationError
      })}
    </PageSection>
  ) : (
    React.createElement(props.component, {
      formData,
      setData,
      onContinue,
      loading,
      validationError
    })
  );
};

interface MultiProductInfoPageProps<T> {
  title: string | JSX.Element;
  description: string | JSX.Element;
  formDataConstructor: (product?: Product) => T;
  products: Product[];
  component: React.FunctionComponent<{
    loading?: boolean;
    validationError?: ValidationError;
    formData: T;
    setData: (field: string, fn: (data: T) => T) => void;
    onContinue: (product: Product) => void;
    onAdd?: (event: { canceled?: boolean; addAnother?: boolean; add?: Product }) => void;
    isAdding?: boolean;
    isEditing?: boolean;
  }>;
  hasError: (validationError: ValidationError) => boolean;
  onSubmit: (
    products: Product[]
  ) => Promise<{ Policy: Policy; NextValidationError?: ValidationError }>;
  onContinue: (policy?: Policy) => void;
}

export const MultiProductInfoPage = <T,>(props: MultiProductInfoPageProps<T>): JSX.Element => {
  const [validationError, setValidationError] = React.useState<ValidationError>();
  const [formData, setFormData] = React.useState<T>(
    props.formDataConstructor(props.products.length === 1 ? props.products[0] : undefined)
  );
  const [savedProducts, setSavedProducts] = React.useState<Product[]>(
    props.products.length === 1 ? [] : props.products
  );
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error>();
  const [formExpanded, setFormExpanded] = React.useState(savedProducts.length === 0);
  const [editingProduct, setEditingProduct] = React.useState<number>();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onContinue = (products: Product[], addOnly?: boolean, collapse?: boolean) => {
    setLoading(true);
    setTimeout(
      () =>
        props
          .onSubmit(products)
          .then(({ Policy, NextValidationError }) => {
            if (NextValidationError) {
              if (props.hasError(NextValidationError)) {
                setValidationError(NextValidationError);
                setSavedProducts(Policy.InsuredItems.slice(0, -1));
                setFormData(
                  props.formDataConstructor(Policy.InsuredItems[Policy.InsuredItems.length - 1])
                );
                setFormExpanded(true);
                return;
              }
            }

            window.scrollTo({ top: 0 });
            setValidationError(undefined);
            setSavedProducts(Policy.InsuredItems);
            setFormData(props.formDataConstructor());

            if (collapse) {
              setFormExpanded(false);
              setEditingProduct(undefined);
            }
            if (!addOnly) {
              props.onContinue(Policy);
            }
          })
          .catch((e) => {
            const err = WrappedError.asWrappedError(e);
            if (err.type?.() === ErrorType.validationError) {
              setValidationError(err.getValidationError());
            } else {
              setError(e);
            }
          })
          .finally(() => setLoading(false)),
      500
    );
  };

  React.useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  React.useEffect(() => {
    if (props.products.length === 0) {
      setFormExpanded(true);
    }
  }, [props.products.length]);

  React.useEffect(() => {
    if (savedProducts.length === 0) {
      setFormExpanded(true);
    }
  }, [savedProducts.length]);

  const setData = (field: string, fn: (prev: T) => T) => {
    if (validationError?.Field === field) {
      setValidationError(undefined);
    }
    setFormData(fn);
  };

  return (
    <>
      <PageSection noBorder customPadding="20px 40px 0px 40px">
        <h1>{props.title}</h1>
        <p>{props.description}</p>
        {savedProducts.length > 0 && (
          <SavedProductListContainer>
            {savedProducts.map((product, i) => (
              <SavedProduct
                product={product}
                isEditing={editingProduct === i}
                onEdit={() => {
                  if (editingProduct !== i) {
                    setFormData(props.formDataConstructor(product));
                    setEditingProduct(i);
                    setFormExpanded(true);
                  } else {
                    setFormData(props.formDataConstructor());
                    setEditingProduct(undefined);
                    setFormExpanded(false);
                  }
                }}
                onDuplicate={() => {
                  setFormData(props.formDataConstructor(product));
                  setEditingProduct(undefined);
                  setFormExpanded(true);
                }}
                onRemove={() => {
                  if (editingProduct === i) {
                    setFormData(props.formDataConstructor());
                    setEditingProduct(undefined);
                    setFormExpanded(false);
                  }
                  setSavedProducts((prev) => prev.filter((_, idx) => idx !== i));
                }}
              />
            ))}
          </SavedProductListContainer>
        )}
      </PageSection>
      <Expandable
        title={
          editingProduct !== undefined
            ? `Editing ${savedProducts[editingProduct].Name}`
            : 'Add a bike'
        }
        onExpand={() => setFormExpanded((prev) => !prev)}
        expanded={formExpanded}
        disabledExpansion={savedProducts.length === 0 || editingProduct !== undefined}
      >
        {React.createElement(props.component, {
          formData: formData,
          setData,
          onContinue: (product) => onContinue([...savedProducts, product]),
          onAdd: (opts) => {
            if (opts.add) {
              if (editingProduct === undefined) {
                onContinue([...savedProducts, opts.add], true, !opts.addAnother);
              } else {
                onContinue(
                  [
                    ...savedProducts.slice(0, editingProduct),
                    opts.add,
                    ...savedProducts.slice(editingProduct + 1)
                  ],
                  true,
                  true
                );
              }
            }
            if (opts.canceled) {
              setFormData(props.formDataConstructor());
              setEditingProduct(undefined);
              setFormExpanded(false);
            }
          },
          isAdding: formExpanded && savedProducts.length > 0,
          isEditing: editingProduct !== undefined,
          loading,
          validationError
        })}
      </Expandable>
      <PageSection>
        {!formExpanded && (
          <ButtonContainer center>
            <Button
              icon={<IoArrowForward />}
              primary
              loading={loading}
              onClick={() => onContinue(savedProducts)}
            >
              Continue
            </Button>
          </ButtonContainer>
        )}
      </PageSection>
    </>
  );
};

const ExpandableTitle = styled.div<{ expanded?: boolean; expandable?: boolean }>`
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 16px 24px;
  border-radius: ${(props) => (!props.expanded ? '10px' : '0')};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #f2f2f2;
  cursor: ${(props) => (props.expandable ? 'pointer' : 'default')};

  &:hover {
    background: ${(props) => (props.expandable ? '#eaeaea' : '#f2f2f2')};
  }

  h2 {
    font-weight: 500;
    color: #000000 !important;
    margin: 0;
    font-size: 1.3em;
  }

  svg {
    font-size: 1.3em;
  }
`;

const Expandable = (
  props: React.PropsWithChildren<{
    title: string;
    expanded?: boolean;
    disabledExpansion?: boolean;
    onExpand: () => void;
  }>
) => (
  <PageSection noBorder noPadding>
    <ExpandableTitle
      onClick={() => {
        if (!props.disabledExpansion) {
          props.onExpand();
        }
      }}
      expanded={props.expanded}
      expandable={!props.disabledExpansion}
    >
      {props.expanded ? <IoChevronDown /> : <IoChevronForward />}
      <h2>{props.title}</h2>
    </ExpandableTitle>
    {props.expanded && (
      <div style={{ padding: '6px 30px', border: '1px solid #f2f2f2', borderTop: '0' }}>
        {props.children}
      </div>
    )}
  </PageSection>
);

const SavedProductListContainer = styled.ul`
  margin: 0;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SavedProductContainer = styled.li`
  width: 100%;
  list-style: none;
  box-sizing: border-box;
  border: 2px solid #0ea5e9;
  border-radius: 10px;
  overflow: hidden;
`;

const SavedProductInfoContainer = styled.div`
  padding: 20px;
  display: flex;
  gap: 8px;
`;

const SavedProductCheckmarkContainer = styled.div`
  font-size: 1.2em;
  color: #0ea5e9;
`;

const SavedProductDetailsContainer = styled.div`
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const SavedProductName = styled.div`
  font-size: 1.1em;
  font-weight: 500;
`;

const SavedProductDetail = styled.div`
  font-size: 0.9em;
  color: #666666;
`;

const SavedProductPriceContainer = styled.div`
  font-size: 1.1em;
  font-weight: 500;
`;

const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
`;

const ActionContainer = styled.div<{ active?: boolean }>`
  flex: 1 0 0;
  padding: 12px 0px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  font-weight: 500;
  font-size: 0.9em;
  color: ${(props) => (props.active ? 'white' : '#666666')};
  background: ${(props) => (props.active ? '#0EA5E9' : 'transparent')};

  transition: all 0.1s ease-in-out;

  border-top: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;

  cursor: pointer;

  &:last-child {
    border-right: 0;
  }

  &:hover {
    background: ${(props) => (props.active ? '0EA5E9' : '#f2f2f2')};
  }

  @media (max-width: 450px) {
    font-size: 1em;

    .action-title {
      display: none;
    }
  }
`;

const getDetail = (product: Product): string | null => {
  switch (product.Type) {
    case ProductType.bike: {
      const details = product.Details as BikeProduct;
      return details?.FrameSerialNumber || 'Serial number pending';
    }
    default:
      return null;
  }
};

const getInsuredValue = (product: Product): number => {
  switch (product.Type) {
    case ProductType.bike: {
      const details = product.Details as BikeProduct;
      if (details.TotalInsuredValue?.Amount) {
        return details.TotalInsuredValue?.Amount;
      }
      return product.Price.Amount;
    }

    default:
      return product.Price.Amount;
  }
};

const SavedProduct = (props: {
  product: Product;
  isEditing?: boolean;
  onEdit: () => void;
  onDuplicate: () => void;
  onRemove: () => void;
}) => (
  <SavedProductContainer>
    <SavedProductInfoContainer>
      <SavedProductCheckmarkContainer>
        <IoCheckmarkCircle />
      </SavedProductCheckmarkContainer>
      <SavedProductDetailsContainer>
        <SavedProductName>{props.product.Name}</SavedProductName>
        <SavedProductDetail>{getDetail(props.product)}</SavedProductDetail>
      </SavedProductDetailsContainer>
      <SavedProductPriceContainer>
        {new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: props.product.Price.Currency || 'usd',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(getInsuredValue(props.product))}
      </SavedProductPriceContainer>
    </SavedProductInfoContainer>
    <ActionsContainer>
      <Action icon={<IoPencil />} title="Edit" onClick={props.onEdit} active={props.isEditing} />
      <Action icon={<IoDuplicate />} title="Duplicate" onClick={props.onDuplicate} />
      <Action icon={<IoTrash />} title="Remove" onClick={props.onRemove} />
    </ActionsContainer>
  </SavedProductContainer>
);

const Action = (props: {
  icon: JSX.Element;
  title: string;
  onClick: () => void;
  active?: boolean;
}) => (
  <ActionContainer onClick={props.onClick} active={props.active}>
    {props.icon}
    <div className="action-title">{props.title}</div>
  </ActionContainer>
);
