import * as React from 'react';
import { IoInformationCircleOutline } from 'react-icons/io5';
import styled from 'styled-components';

const TooltipContainer = styled.div<{ backgroundColor?: string }>`
  background: ${(props) => props.backgroundColor || '#f5f5f5'};
  padding: 20px 20px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;

  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const IconContainer = styled.div`
  font-size: 1.2em;
`;

const TextContainer = styled.div`
  flex-grow: 1;
`;

const TitleText = styled.div`
  font-weight: 500;
  font-size: 0.9em;
  padding-bottom: 5px;
`;

const DescriptionText = styled.div`
  font-size: 0.8em;
  color: #666666;
`;

interface TooltipProps {
  title?: string;
  backgroundColor?: string;
}

export const Tooltip: React.FunctionComponent<React.PropsWithChildren<TooltipProps>> = (
  props
): JSX.Element => (
  <TooltipContainer {...props}>
    <IconContainer aria-hidden>
      <IoInformationCircleOutline />
    </IconContainer>
    <ContentContainer>
      <TextContainer>
        {props.title && <TitleText>{props.title}</TitleText>}
        <DescriptionText>{props.children}</DescriptionText>
      </TextContainer>
    </ContentContainer>
  </TooltipContainer>
);
