import * as React from 'react';

import { FormContainer, FormRow, FormColumn, FormRowHeader } from '@oysterjs/ui/Form/builder';
import { TextInput } from '@oysterjs/ui/Form/text';
import { Select } from '@oysterjs/ui/Form/select';
import { JewelryFormData } from './collect';
import { JewelryProduct } from '@oysterjs/types';

interface QuickQuoteProps {
  onPriceUpdate: (value: number, data: JewelryFormData) => void;
}

export const JewelryQuickQuote: React.FunctionComponent<
  React.PropsWithChildren<QuickQuoteProps>
> = (props) => {
  const [formData, setFormData] = React.useState<JewelryFormData>({
    Price: '',
    Details: {} as JewelryProduct
  });

  const setData = (fn: (prev: JewelryFormData) => JewelryFormData) => {
    setFormData((prev) => {
      const next = fn(prev);
      props.onPriceUpdate(getPrice(next), next);
      return next;
    });
  };

  const getPrice = (data: JewelryFormData): number => {
    if (!data.ZipCode || !data.Price || !data.Details.Type) {
      return 0;
    }
    const amount = parseFloat(data.Price);
    if (!amount || isNaN(amount)) {
      return 0;
    }
    return Math.max(0.015 * amount, 25);
  };

  return (
    <FormContainer>
      <FormRowHeader
        title="Residential Zip Code"
        description="Enter the zip code in which you live and/or store your jewelry."
      />
      <FormRow>
        <FormColumn>
          <TextInput
            style={{ maxWidth: '200px' }}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData((prev) => ({ ...prev, ZipCode: value }));
            }}
            value={formData.ZipCode}
            inputMode="numeric"
            autoComplete="postal-code"
          />
        </FormColumn>
      </FormRow>
      <FormRowHeader
        title="What type of jewelry is this?"
        description="Choose the type of jewelry you'd like to insure."
      />
      <FormRow>
        <FormColumn>
          <Select
            options={[
              { displayValue: '', value: '' },
              { displayValue: 'Anklets', value: 'anklets' },
              { displayValue: 'Bracelet', value: 'bracelet' },
              { displayValue: 'Earrings', value: 'earrings' },
              { displayValue: 'Necklace', value: 'necklace' },
              { displayValue: 'Ring', value: 'ring' },
              { displayValue: 'Watch', value: 'watch' },
              { displayValue: 'Something Else', value: 'something_else' }
            ]}
            style={{ maxWidth: '200px' }}
            onChange={(value) =>
              setData((prev) => ({
                ...prev,
                Details: { ...prev.Details, Type: value }
              }))
            }
            value={formData.Details.Type || ''}
          />
        </FormColumn>
      </FormRow>
      <FormRowHeader
        title="Jewelry Value"
        description="Enter the total value of the jewelry you'd like to insure."
      />
      <FormRow>
        <FormColumn>
          <TextInput
            currency
            style={{ maxWidth: '200px' }}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData((prev) => ({ ...prev, Price: value }));
            }}
            value={formData.Price}
            inputMode="decimal"
          />
        </FormColumn>
      </FormRow>
    </FormContainer>
  );
};
