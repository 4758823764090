import styled, { keyframes } from 'styled-components';

export const TextSkeleton = styled.span`
  display: inline;
  margin: 0px 5px;
  border-radius: 5px;
  padding: 0px 2px;
  background: rgba(255, 255, 255, 0.3);
  animation: ${keyframes`
    0% {
      background: rgba(255, 255, 255, 0.3);
    }
    25% {
      background: rgba(255, 255, 255, 0.45);
    }
    100% {
      background: rgba(255, 255, 255, 0.3);
    }
  `} 2s infinite;
  color: transparent;
  font-weight: bold;
  word-break: break-all;
`;
