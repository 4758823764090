import * as React from 'react';

import { FormContainer, FormRow, FormColumn, FormRowHeader } from '@oysterjs/ui/Form/builder';
import { TextInput } from '@oysterjs/ui/Form/text';
import { MotorProductType, ProductType } from '@oysterjs/types';
import { MotorFormData } from './collect';

interface QuickQuoteProps {
  productType: ProductType;
  onPriceUpdate: (value: number, data: MotorFormData) => void;
}

export const MotorQuickQuote: React.FunctionComponent<React.PropsWithChildren<QuickQuoteProps>> = (
  props
) => {
  const [, setFormData] = React.useState<MotorFormData>({
    ProductType: props.productType,
    ZipCode: '',
    Price: '',
    Details: {
      ModelYear: '',
      PurchaseYear: '',
      Make: '',
      Model: '',
      VIN: '',
      CCSize: '',
      Type: '' as MotorProductType,
      EstimatedAnnualMileage: ''
    }
  });

  const setData = (fn: (prev: MotorFormData) => MotorFormData) => {
    setFormData((prev) => {
      const next = fn(prev);
      props.onPriceUpdate(getPrice(next), next);
      return next;
    });
  };

  const getPrice = (data: MotorFormData): number => {
    if (!data.Price || isNaN(parseFloat(data.Price))) {
      return 0;
    }
    return Math.floor(parseFloat(data.Price) * 0.12 * (1 - Math.random() / 10));
  };

  return (
    <FormContainer>
      <FormRowHeader
        title="Residential Zip Code"
        description="Enter the zip code in which you live and/or ride your vehicle."
      />
      <FormRow>
        <FormColumn>
          <TextInput
            style={{ maxWidth: '200px' }}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData((prev) => ({ ...prev, ZipCode: value }));
            }}
            inputMode="numeric"
            autoComplete="postal-code"
          />
        </FormColumn>
      </FormRow>
      <FormRowHeader
        title="Vehicle Value"
        description="Enter the value of the vehicle you want to insure."
      />
      <FormRow>
        <FormColumn>
          <TextInput
            currency
            style={{ maxWidth: '200px' }}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData((prev) => ({ ...prev, Price: value }));
            }}
            inputMode="decimal"
          />
        </FormColumn>
      </FormRow>
    </FormContainer>
  );
};
