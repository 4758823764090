import { createTheme, Theme } from '@material-ui/core';

export const getTheme = (primaryColor: string, tonalOffset: number): Theme | null => {
  return createTheme({
    palette: {
      contrastThreshold: 4.5,
      tonalOffset: tonalOffset,
      primary: {
        main: primaryColor
      }
    }
  });
};

export const isDark = (hexColor: string): boolean => {
  const m = hexColor.match(/^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i);
  const red = m ? parseInt(m[1], 16) : -1;
  const green = m ? parseInt(m[2], 16) : -1;
  const blue = m ? parseInt(m[3], 16) : -1;
  return red >= 0 && green >= 0 && blue >= 0
    ? (0.2126 * red) / 255 + (0.7152 * green) / 255 + (0.0722 * blue) / 255 >= 0.5
      ? true
      : false
    : true; // default to dark
};
