import apm, { init as initApm } from '@oysterjs/core/apm';
initApm();

import { init as initAppConfiguration } from '@oysterjs/core/api/appConfiguration';
initAppConfiguration();

import '../dashboard/style.scss';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route as BrowserRoute,
  useHistory,
  useRouteMatch
} from 'react-router-dom';

import * as Sentry from '@sentry/react';
const Route = Sentry.withSentryRouting(BrowserRoute);

import { MerchantIntegrationType, ProductType } from '@oysterjs/types';
import config from '@oysterjs/core/config';

import IntroPage from './pages/intro';
import LandingPage, { PolicyOfferLandingPage, RentalLandingPage } from './pages/landing';
import CompletePage from './pages/complete';
import MotorInsuranceApp from './pages/motor';
import BikeInsuranceApp from './pages/bike';
import JewelryInsuranceApp from './pages/jewelry';
import ElectronicsInsuranceApp from './pages/electronics';
import { useGA } from '@oysterjs/core/ga';
import { PageContainer, PageSection } from '@oysterjs/ui/Page';
import { useGoogleTags } from '@oysterjs/core/analytics/googletags';
import { HubspotChatContextProvider } from '@oysterjs/core/hubspot';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';
import { RentalCustomerForm } from '@oysterjs/partners/pages/rental/RentalCustomerForm';
import { RentalCompletionPage } from '@oysterjs/partners/pages/rental/RentalCompletionPage';
import { RentalAgreement } from '@oysterjs/partners/pages/rental/RentalAgreement';
import { OysterLogo } from '@oysterjs/ui/Logo';

const NotFound = () => (
  <PageContainer>
    <PageSection>
      <h1>404 Page Not Found</h1>
      <p>
        The page you're looking for could not be found. You can visit{' '}
        <a href="https://www.withoyster.com">our homepage here.</a>
      </p>
      <img
        height={250}
        style={{ display: 'block', margin: 'auto', padding: '40px 0px 0px 0px' }}
        src="https://dashboard.withoyster.com/images/no_policies.svg"
      />
    </PageSection>
  </PageContainer>
);

const App = () => {
  useRouteMatch();
  const history = useHistory();

  if (config().environment === 'production') {
    useGA('G-9Z1N2TCGS1');
    useGoogleTags();
  }

  const onSelectProduct = (selectedProductType: ProductType) => {
    history.push(`/${selectedProductType}`);
  };

  React.useEffect(() => {
    let path = window.location.pathname;
    if (window.location.pathname.startsWith('/m/')) {
      path = '/m/:externalID/:productType';
    }
    if (window.location.pathname.startsWith('/q/')) {
      path = '/q/:externalID/:productType';
    }
    if (window.location.pathname.startsWith('/emp/')) {
      path = '/emp/:externalID/:productType';
    }
    if (window.location.pathname.indexOf('/pol_') !== -1) {
      path = window.location.pathname.replace(/\/pol_[^/$]+\//, '/:id/');
    }

    apm().sendEvent('oyster.d2c.page_load', {
      route: path,
      path: window.location.pathname,
      query: window.location.search
    });
  }, [window.location.href]);

  return (
    <HubspotChatContextProvider>
      <Switch>
        <Route exact path="/">
          <IntroPage onContinue={onSelectProduct} />
        </Route>
        <Route exact path="/offer/:id">
          {(props) => (
            <ErrorBoundary>
              <PolicyOfferLandingPage policyId={props.match?.params.id || ''} />
            </ErrorBoundary>
          )}
        </Route>
        <Route exact path="/m/:externalID/rental/:bookingId?">
          {(props) => (
            <ErrorBoundary>
              <RentalLandingPage
                externalId={props.match?.params.externalID}
                bookingId={props.match?.params.bookingId}
                integrationType={MerchantIntegrationType.referral_link}
              />
            </ErrorBoundary>
          )}
        </Route>
        <Route exact path="/m/:externalID/:productType?">
          {(props) => (
            <LandingPage
              externalID={props.match?.params.externalID || ''}
              productType={props.match?.params.productType}
            />
          )}
        </Route>
        <Route exact path="/q/:externalID/rental/:bookingId?">
          {(props) => (
            <ErrorBoundary>
              <RentalLandingPage
                externalId={props.match?.params.externalID}
                bookingId={props.match?.params.bookingId}
                integrationType={MerchantIntegrationType.qr_code}
              />
            </ErrorBoundary>
          )}
        </Route>
        <Route exact path="/q/:externalID/:productType?">
          {(props) => (
            <LandingPage
              externalID={props.match?.params.externalID || ''}
              productType={props.match?.params.productType}
            />
          )}
        </Route>
        <Route exact path="/a/:externalID/:productType?">
          {(props) => (
            <LandingPage
              externalID={props.match?.params.externalID || ''}
              productType={props.match?.params.productType}
            />
          )}
        </Route>
        <Route exact path="/emp/:externalID/:productType?">
          {(props) => (
            <LandingPage
              externalID={props.match?.params.externalID || ''}
              productType={props.match?.params.productType}
            />
          )}
        </Route>
        <Route path="/app/offroad_vehicle">
          <MotorInsuranceApp />
        </Route>
        <Route path="/app/motorcycle">
          <MotorInsuranceApp />
        </Route>
        <Route path="/app/bike">
          <BikeInsuranceApp />
        </Route>
        <Route path="/app/jewelry">
          <JewelryInsuranceApp />
        </Route>
        <Route path="/app/electronics">
          <ElectronicsInsuranceApp />
        </Route>
        <Route path="/app/phone">
          <ElectronicsInsuranceApp />
        </Route>
        <Route path="/offroad_vehicle">
          <LandingPage productType={ProductType.offroad} />
        </Route>
        <Route path="/motorcycle">
          <LandingPage productType={ProductType.motorcycle} />
        </Route>
        <Route path="/bike">
          <LandingPage productType={ProductType.bike} />
        </Route>
        <Route path="/jewelry">
          <LandingPage productType={ProductType.jewelry} />
        </Route>
        <Route path="/phone">
          <LandingPage productType={ProductType.phone} />
        </Route>
        <Route path="/electronics">
          <LandingPage productType={ProductType.electronics} />
        </Route>
        <Route
          path="/policy/:id/complete"
          render={(props) => <CompletePage policyId={props.match.params.id} />}
        />
        <Route
          path="/rental/waiver/:id/confirm"
          render={(props) => (
            <PageContainer width={700}>
              <PageSection centered>
                <OysterLogo scale={1.5} inline />
              </PageSection>
              <PageSection noBorder>
                <ErrorBoundary forceMobile>
                  <RentalCustomerForm
                    waiverId={props.match.params.id}
                    confirmationReturnUrl={
                      window.location.origin +
                      `/rental/waiver/${props.match.params.id}/complete` +
                      window.location.search
                    }
                  />
                </ErrorBoundary>
              </PageSection>
            </PageContainer>
          )}
        />
        <Route
          path="/rental/waiver/:id/complete"
          render={(props) => (
            <PageContainer width={700}>
              <PageSection centered>
                <OysterLogo scale={1.5} inline />
              </PageSection>
              <PageSection noBorder>
                <ErrorBoundary forceMobile>
                  <RentalCompletionPage
                    waiverId={props.match.params.id}
                    onClose={
                      new URLSearchParams(window.location.search).get('from') === 'partner'
                        ? () => window.close()
                        : undefined
                    }
                  />
                </ErrorBoundary>
              </PageSection>
            </PageContainer>
          )}
        />
        <Route
          path="/rental/booking/:id/confirm"
          render={(props) => (
            <PageContainer width={700}>
              <PageSection centered>
                <OysterLogo scale={1.5} inline />
              </PageSection>
              <PageSection noBorder>
                <ErrorBoundary forceMobile>
                  <RentalCustomerForm
                    bookingId={props.match.params.id}
                    confirmationReturnUrl={
                      window.location.origin +
                      `/rental/booking/${props.match.params.id}/complete` +
                      window.location.search
                    }
                  />
                </ErrorBoundary>
              </PageSection>
            </PageContainer>
          )}
        />
        <Route
          path="/rental/booking/:id/complete"
          render={(props) => (
            <PageContainer width={700}>
              <PageSection centered>
                <OysterLogo scale={1.5} inline />
              </PageSection>
              <PageSection noBorder>
                <ErrorBoundary forceMobile>
                  <RentalCompletionPage
                    bookingId={props.match.params.id}
                    onClose={
                      new URLSearchParams(window.location.search).get('from') === 'partner'
                        ? () => window.close()
                        : undefined
                    }
                  />
                </ErrorBoundary>
              </PageSection>
            </PageContainer>
          )}
        />
        <Route
          path="/rental/waiver/:id/agreement"
          render={(props) => (
            <PageContainer width={700}>
              <PageSection centered>
                <OysterLogo scale={1.5} inline />
              </PageSection>
              <PageSection noBorder>
                <ErrorBoundary forceMobile>
                  <RentalAgreement waiverId={props.match.params.id} />
                </ErrorBoundary>
              </PageSection>
            </PageContainer>
          )}
        />
        <Route exact path="/404">
          <NotFound />
        </Route>
        <Redirect to="/" />
      </Switch>
    </HubspotChatContextProvider>
  );
};

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
