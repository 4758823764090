import {
  AttachmentFile,
  Insured,
  Merchant,
  MerchantIntegration,
  Policy,
  PolicyReferralChannelInfo,
  Price,
  Product,
  ReferralSubChannel as ReferralSubChannel,
  ValidationError
} from '@oysterjs/types';
import { Delete, Get, getEncodedQueryString, Post, Put } from './base';
import { userCreatePolicy } from './user';
import config from '@oysterjs/core/config';
import { userCompletePolicy, userGetPaymentSession } from './user';

export const sdkGetIntegrationConfig = () =>
  Get<{ Integration: MerchantIntegration; Merchant: Merchant }>(
    `/sdk/integration/${config().merchant?.integrationId || ''}`,
    {
      headers: { 'X-Merchant-API-Key': config().merchant?.apiKey || '' }
    }
  ).then((res) => res);

export const sdkGetPolicy = (policyId: string) =>
  Get<{ Policy: Policy }>(`/sdk/policy/${policyId}`, {
    headers: { 'X-Merchant-API-Key': config().merchant?.apiKey || '' }
  }).then((d) => d.Policy);

export interface CreatePolicyOptions {
  merchantApiKey?: string;
  integrationID?: string;
  insured?: Insured;
  allowValidationErrors?: boolean;
  referralChannel?: PolicyReferralChannelInfo;
  referralSubChannel?: ReferralSubChannel;
}

export const sdkCreateQuote = (
  products: Product[],
  opts?: CreatePolicyOptions
): Promise<{ Policy?: Policy }> =>
  Post(
    `/sdk/quote?${getEncodedQueryString({
      AllowValidationErrors: opts?.allowValidationErrors
    })}`,
    {
      Products: products,
      Insured: opts?.insured,
      ReferralChannelInfo: opts?.referralChannel,
      ReferralSubChannel: opts?.referralSubChannel
    },
    {
      headers: {
        'X-Merchant-API-Key': opts?.merchantApiKey || config().merchant?.apiKey || '',
        'X-Merchant-Integration-ID': opts?.integrationID || config().merchant?.integrationId || ''
      }
    }
  );

export const sdkCreatePolicy = (
  products: Product[],
  opts?: CreatePolicyOptions
): Promise<{ Policy: Policy }> => {
  switch (config().serviceName) {
    case 'oysterjs':
    case 'getoyster':
      return Post<{ Policy: Policy }>(
        `/sdk/policy?${getEncodedQueryString({
          AllowValidationErrors: opts?.allowValidationErrors
        })}`,
        {
          Products: products,
          Insured: opts?.insured,
          ReferralChannelInfo: opts?.referralChannel,
          ReferralSubChannel: opts?.referralSubChannel
        },
        {
          headers: {
            'X-Merchant-API-Key': opts?.merchantApiKey || config().merchant?.apiKey || '',
            'X-Merchant-Integration-ID':
              opts?.integrationID || config().merchant?.integrationId || ''
          }
        }
      );

    case 'dashboard':
      return userCreatePolicy(products, opts);

    default:
      throw new Error(`Invalid service name for policy creation: ${config().serviceName}`);
  }
};

export const sdkUpdatePolicy = (policy: Policy, isD2CFlow?: boolean) =>
  Put<{ Policy: Policy; NextValidationError?: ValidationError }>(
    `/sdk/policy/${policy.ID}`,
    { Policy: policy, IsD2CFlow: !!isD2CFlow },
    {
      headers: { 'X-Merchant-API-Key': config().merchant?.apiKey || '' }
    }
  );

export const sdkGetPolicyPremium = (policyId: string) =>
  Get<{ Premium: Price; Underwriter?: string }>(`/sdk/policy/${policyId}/premium`);

export const sdkValidatePolicy = (policyId: string): Promise<Policy> =>
  Post<{ Policy: Policy }>(`/sdk/policy/${policyId}/partnerValidation`).then((d) => d.Policy);

export const sdkConfirmPolicy = (policyId: string, insured?: Insured): Promise<Policy> =>
  Post<{ Policy: Policy }>(
    `/sdk/policy/${policyId}/confirm`,
    {
      Insured: insured
    },
    {
      headers: { 'X-Merchant-API-Key': config().merchant?.apiKey || '' }
    }
  ).then((d) => d.Policy);

export const sdkGetPaymentSession = (policyId: string): Promise<string> => {
  switch (config().serviceName) {
    case 'oysterjs':
    case 'getoyster':
      return Post<{ StripeClientSecret: string }>(`/sdk/policy/${policyId}/payment`).then((d) => {
        return d.StripeClientSecret;
      });

    case 'dashboard':
      return userGetPaymentSession(policyId);

    default:
      throw new Error(`Invalid service name for payment session: ${config().serviceName}`);
  }
};

export const sdkCompletePolicy = (policyId: string) => {
  switch (config().serviceName) {
    case 'oysterjs':
    case 'getoyster':
      return Post<{ Policy: Policy; Redirect?: string }>(
        `/sdk/policy/${policyId}/complete`,
        undefined,
        {
          headers: {
            'X-Merchant-API-Key': config().merchant?.apiKey || ''
          }
        }
      );

    case 'dashboard':
      return userCompletePolicy(policyId);

    default:
      throw new Error(`Invalid service name for policy completion: ${config().serviceName}`);
  }
};

export const sdkGetPolicyFilesMetadata = (policyId: string) => {
  return Get<{ Metadata: AttachmentFile[] }>(`/sdk/policy/${policyId}/files/metadata`).then(
    (d) => d.Metadata || []
  );
};

export const sdkCreateTemporaryAttachments = (
  fileRole: string,
  files: File[],
  existingFileIDs?: string[]
) => {
  const form = new FormData();
  files.forEach((file) => form.append('files[]', file));
  form.append('FileRole', fileRole);
  if (existingFileIDs) {
    form.append('ExistingFileIDs', JSON.stringify(existingFileIDs));
  }
  return Post<{ Metadata: AttachmentFile[] }>(`/sdk/attachments`, undefined, { body: form }).then(
    (d) => d.Metadata || []
  );
};

export const sdkDeletePolicyAttachment = (policyId: string, fileID: string) => {
  return Delete<{ Metadata: AttachmentFile[] }>(
    `/user/policy/${policyId}/attachment/${fileID}`,
    undefined
  ).then((d) => d.Metadata || []);
};
