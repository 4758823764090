import * as React from 'react';

import { PageSection } from '@oysterjs/ui/Page';
import {
  BikePowerSource,
  BikeProduct,
  Product,
  ProductType,
  ValidationError
} from '@oysterjs/types';
import { TextInput } from '@oysterjs/ui/Form/text';
import { Button, ButtonContainer } from '@oysterjs/ui/Button';
import { IoArrowForward } from 'react-icons/io5';
import { Select } from '@oysterjs/ui/Form/select';
import { FormColumn, FormContainer, FormRow, FormRowHeader } from '@oysterjs/ui/Form/builder';
import {
  RequirementCheckboxContainer,
  RequirementContentContainer,
  RequirementDescription,
  RequirementItemContainer,
  RequirementTitle
} from '@oysterjs/ui/common';
import { Checkbox } from '@oysterjs/ui/Form/checkbox';

export interface BikeFormData {
  ZipCode?: string;
  Price: string;
  TotalInsuredValue?: string;
  HasNotReceived?: boolean;
  Details: BikeProduct;
}

interface CollectBikeInfoProps {
  loading?: boolean;
  validationError?: ValidationError;
  formData: BikeFormData;
  setData: (field: string, fn: (data: BikeFormData) => BikeFormData) => void;
  onContinue: (product: Product) => void;
  onAdd?: (event: { canceled?: boolean; addAnother?: boolean; add?: Product }) => void;
  isAdding?: boolean;
  isEditing?: boolean;
}

export const CollectBikeInfo = (props: CollectBikeInfoProps) => {
  const [validationError, setValidationError] = React.useState<ValidationError>();

  React.useEffect(() => {
    setValidationError(props.validationError);
  }, [JSON.stringify(props.validationError)]);

  const setData = (field: string, fn: (data: BikeFormData) => BikeFormData) => {
    if (validationError?.Field === field) {
      setValidationError(undefined);
    }
    props.setData(field, fn);
  };

  const validate = (formData: BikeFormData) => {
    if (!formData.Details.Make) {
      return {
        Field: 'Make',
        Message: 'Please enter a manufacturer.'
      };
    }
    if (!formData.Details.Model) {
      return {
        Field: 'Model',
        Message: 'Please enter a model.'
      };
    }
    if (!/^20\d\d$/.test(formData.Details?.ModelYear?.toString() || '')) {
      return {
        Field: 'ModelYear',
        Message: 'Please enter a valid model year.'
      };
    }
    if (!formData.Details.Type) {
      return {
        Field: 'Type',
        Message: 'Please select a bike type.'
      };
    }
    if (!formData.Details.FrameType) {
      return {
        Field: 'FrameType',
        Message: 'Please select a frame material.'
      };
    }
    if (isNaN(parseFloat(formData.Price))) {
      return {
        Field: 'Price',
        Message: 'Please enter a valid price.'
      };
    }
    if (formData.TotalInsuredValue && isNaN(parseFloat(formData.TotalInsuredValue))) {
      return {
        Field: 'TotalInsuredValue',
        Message: 'Please enter a valid amount.'
      };
    }
    if (
      formData.TotalInsuredValue &&
      parseFloat(formData.TotalInsuredValue) < parseFloat(formData.Price)
    ) {
      return {
        Field: 'TotalInsuredValue',
        Message: 'If specified, this must be more than the bike price.'
      };
    }
    if (
      !formData.Details?.PurchaseYear ||
      !/^20\d\d$/.test(formData.Details.PurchaseYear.toString())
    ) {
      return {
        Field: 'PurchaseYear',
        Message: 'Please enter a valid purchase year.'
      };
    }
    if (
      formData.Details.PurchaseYear &&
      parseInt(formData.Details.PurchaseYear.toString()) > new Date().getFullYear()
    ) {
      return {
        Field: 'PurchaseYear',
        Message: 'The purchase year cannot be in the future.'
      };
    }
    return null;
  };

  const onAddAnother = (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => onNext(e, (product) => props.onAdd?.({ addAnother: true, add: product }));

  const onAddSingle = (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => onNext(e, (product) => props.onAdd?.({ addAnother: false, add: product }));

  const onSubmit = (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => onNext(e, props.onContinue);

  const onNext = (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    callback?: (product: Product) => void
  ) => {
    e.preventDefault();

    const err = validate(props.formData);
    if (err) {
      setValidationError(err);
      return;
    }

    callback?.({
      Type: ProductType.bike,
      SKU: '',
      Name: `${props.formData.Details.Make} ${props.formData.Details.Model}`,
      Description: '',
      ImageURL: '',
      Quantity: 1,
      Price: {
        Amount: parseFloat(props.formData.Price.replace(/[^.0-9]/, '')),
        Currency: 'usd'
      },
      HasNotReceived: props.formData.HasNotReceived,
      Details: {
        ...props.formData.Details,
        TotalInsuredValue: {
          Amount: parseFloat(props.formData.TotalInsuredValue?.replace(/[^.0-9]/, '') || '0'),
          Currency: 'usd'
        }
      }
    });
  };

  return (
    <FormContainer onSubmit={onSubmit}>
      <FormRowHeader
        title="Which bike are you insuring?"
        description="Specify the manufacturer, model, and model year of the bike."
      />
      <FormRow breakMobile>
        <FormColumn title="Manufacturer">
          <TextInput
            error={validationError?.Field === 'Make' && validationError?.Message}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData('Make', (prev) => ({
                ...prev,
                Details: { ...prev.Details, Make: value }
              }));
            }}
            value={props.formData.Details.Make}
          />
        </FormColumn>
        <FormColumn title="Model">
          <TextInput
            error={validationError?.Field === 'Model' && validationError?.Message}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData('Model', (prev) => ({
                ...prev,
                Details: { ...prev.Details, Model: value }
              }));
            }}
            value={props.formData.Details.Model}
          />
        </FormColumn>
      </FormRow>
      <FormRow breakMobile>
        <FormColumn title="Model Year">
          <TextInput
            error={validationError?.Field === 'ModelYear' && validationError?.Message}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData('ModelYear', (prev) => ({
                ...prev,
                Details: { ...prev.Details, ModelYear: value }
              }));
            }}
            value={props.formData.Details.ModelYear}
            inputMode="numeric"
          />
        </FormColumn>
        <FormColumn title="Serial Number">
          <TextInput
            disabled={props.formData.Details.IsPendingSerialNumber}
            error={validationError?.Field === 'FrameSerialNumber' && validationError?.Message}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData('FrameSerialNumber', (prev) => ({
                ...prev,
                Details: {
                  ...prev.Details,
                  IsPendingSerialNumber: value === '',
                  FrameSerialNumber: value
                }
              }));
            }}
            value={props.formData.Details.FrameSerialNumber}
          />
        </FormColumn>
      </FormRow>
      <FormRow breakMobile>
        <FormColumn>
          <RequirementItemContainer
            style={{ padding: '0px', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              setData('HasNotReceived', (prev) => ({
                ...prev,
                HasNotReceived: !prev.HasNotReceived
              }));
            }}
          >
            <RequirementCheckboxContainer>
              <Checkbox
                label="HasNotReceived"
                checked={props.formData.HasNotReceived}
                onChange={() => {
                  setData('HasNotReceived', (prev) => ({
                    ...prev,
                    HasNotReceived: !prev.HasNotReceived
                  }));
                }}
              />
            </RequirementCheckboxContainer>
            <RequirementContentContainer>
              <RequirementTitle>I have not received my bike.</RequirementTitle>
              <RequirementDescription>
                You can still submit your application now and activate your policy after you've
                received your bike.
              </RequirementDescription>
            </RequirementContentContainer>
          </RequirementItemContainer>
        </FormColumn>
      </FormRow>
      <FormRowHeader
        title="Which kind of bike do you have?"
        description="Choose the bike type and frame material that most closely matches your bike."
      />
      <FormRow breakMobile>
        <FormColumn title="Bike Type">
          <Select
            options={[
              { value: '' },
              { displayValue: 'Road/Track', value: 'road_track' },
              { displayValue: 'Mountain', value: 'mountain' },
              { displayValue: 'Hybrid/Cruiser', value: 'hybrid_cruiser' },
              { displayValue: 'Recumbent', value: 'recumbent' },
              { displayValue: 'Tandem', value: 'tandem' },
              { displayValue: 'BMX', value: 'bmx' },
              { displayValue: 'Tricycle', value: 'tricycle' }
            ]}
            error={validationError?.Field === 'Type' && validationError?.Message}
            onChange={(value) =>
              setData('Type', (prev) => ({
                ...prev,
                Details: { ...prev.Details, Type: value }
              }))
            }
            value={props.formData.Details.Type}
          />
        </FormColumn>
        <FormColumn title="Frame Material">
          <Select
            options={[
              { value: '' },
              { displayValue: 'Aluminum', value: 'aluminum' },
              { displayValue: 'Carbon Fiber', value: 'carbon_fiber' },
              { displayValue: 'Steel', value: 'steel' },
              { displayValue: 'Titanium', value: 'titanium' },
              { displayValue: 'Wood', value: 'wood' },
              { displayValue: 'Other', value: 'other' }
            ]}
            error={validationError?.Field === 'FrameType' && validationError?.Message}
            onChange={(value) =>
              setData('FrameType', (prev) => ({
                ...prev,
                Details: { ...prev.Details, FrameType: value }
              }))
            }
            value={props.formData.Details.FrameType}
          />
        </FormColumn>
      </FormRow>
      <FormRow breakMobile>
        <FormColumn>
          <RequirementItemContainer
            style={{ padding: '0px', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              setData('PowerSource', (prev) => ({
                ...prev,
                Details: {
                  ...prev.Details,
                  PowerSource: !prev.Details.PowerSource
                    ? BikePowerSource.pedalAssist20MPH
                    : BikePowerSource.none
                }
              }));
            }}
          >
            <RequirementCheckboxContainer>
              <Checkbox
                label="PowerSource"
                checked={!!props.formData.Details.PowerSource}
                onChange={() =>
                  setData('PowerSource', (prev) => ({
                    ...prev,
                    Details: {
                      ...prev.Details,
                      PowerSource: !prev.Details.PowerSource
                        ? BikePowerSource.pedalAssist20MPH
                        : BikePowerSource.none
                    }
                  }))
                }
              />
            </RequirementCheckboxContainer>
            <RequirementContentContainer>
              <RequirementTitle>This is an electric or pedal-assisted bike.</RequirementTitle>
            </RequirementContentContainer>
          </RequirementItemContainer>
        </FormColumn>
      </FormRow>
      {props.formData.Details.PowerSource && (
        <>
          <FormRow breakMobile>
            <FormColumn title="E-Bike Classification">
              <Select
                options={[
                  { displayValue: 'Class I', value: 'pedal_assist_20_mph' },
                  { displayValue: 'Class II', value: 'throttle_assist_20_mph' },
                  { displayValue: 'Class III', value: 'pedal_assist_28_mph' },
                  { displayValue: 'Other', value: 'other' }
                ]}
                style={{ maxWidth: '200px' }}
                value={props.formData.Details.PowerSource}
                error={validationError?.Field === 'PowerSource' && validationError?.Message}
                onChange={(value) =>
                  setData('PowerSource', (prev) => ({
                    ...prev,
                    Details: {
                      ...prev.Details,
                      PowerSource: value
                    }
                  }))
                }
              />
            </FormColumn>
          </FormRow>
          <FormRow breakMobile>
            <RequirementItemContainer
              style={{ padding: '0px', cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation();
                setData('CanUnlock', (prev) => ({
                  ...prev,
                  Details: {
                    ...prev.Details,
                    CanUnlock: !prev.Details.CanUnlock ? true : false
                  }
                }));
              }}
            >
              <RequirementCheckboxContainer>
                <Checkbox
                  label="CanUnlock"
                  checked={!!props.formData.Details.CanUnlock}
                  onChange={() =>
                    setData('CanUnlock', (prev) => ({
                      ...prev,
                      Details: {
                        ...prev.Details,
                        CanUnlock: !prev.Details.CanUnlock ? true : false
                      }
                    }))
                  }
                />
              </RequirementCheckboxContainer>
              <RequirementContentContainer>
                <RequirementTitle>
                  This eBike can be unlocked to speeds higher than 28 mph.
                </RequirementTitle>
              </RequirementContentContainer>
            </RequirementItemContainer>
          </FormRow>
        </>
      )}
      <FormRowHeader
        title="Purchase information"
        description="Enter the price you bought your bike for. If you have additional gear or customizations, enter the total value you'd like to insure."
      />
      <FormRow breakMobile>
        <FormColumn title="Bike Price">
          <TextInput
            currency
            error={validationError?.Field === 'Price' && validationError?.Message}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData('Price', (prev) => ({ ...prev, Price: value }));
            }}
            value={props.formData.Price}
            inputMode="decimal"
          />
        </FormColumn>
        <FormColumn title="Total Value" optional>
          <TextInput
            currency
            error={validationError?.Field === 'TotalInsuredValue' && validationError?.Message}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData('TotalInsuredValue', (prev) => ({ ...prev, TotalInsuredValue: value }));
            }}
            value={
              props.formData.TotalInsuredValue || props.formData.Details.TotalInsuredValue?.Amount
            }
            inputMode="decimal"
          />
        </FormColumn>
      </FormRow>
      <FormRow breakMobile>
        <FormColumn title="Purchase Year">
          <TextInput
            style={{ maxWidth: 'calc(50% - 8px)' }}
            error={validationError?.Field === 'PurchaseYear' && validationError?.Message}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData('PurchaseYear', (prev) => ({
                ...prev,
                Details: { ...prev.Details, PurchaseYear: value }
              }));
            }}
            value={props.formData.Details.PurchaseYear}
            inputMode="numeric"
          />
        </FormColumn>
      </FormRow>
      <FormRow breakMobile>
        <FormColumn>
          <RequirementItemContainer
            style={{ padding: '0px', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              setData('IsSecondHandPurchase', (prev) => ({
                ...prev,
                Details: {
                  ...prev.Details,
                  IsSecondHandPurchase: !prev.Details.IsSecondHandPurchase
                }
              }));
            }}
          >
            <RequirementCheckboxContainer>
              <Checkbox
                label="IsSecondHandPurchase"
                checked={!!props.formData.Details.IsSecondHandPurchase}
                onChange={() =>
                  setData('IsSecondHandPurchase', (prev) => ({
                    ...prev,
                    Details: {
                      ...prev.Details,
                      IsSecondHandPurchase: !prev.Details.IsSecondHandPurchase
                    }
                  }))
                }
              />
            </RequirementCheckboxContainer>
            <RequirementContentContainer>
              <RequirementTitle>This bike is purchased second hand.</RequirementTitle>
            </RequirementContentContainer>
          </RequirementItemContainer>
        </FormColumn>
      </FormRow>
      <PageSection noBorder>
        <ButtonContainer center>
          {props.isAdding && (
            <Button onClick={() => props.onAdd?.({ canceled: true })}>Discard</Button>
          )}
          {props.onAdd && !props.isAdding && (
            <Button onClick={onAddAnother}>Save and add another bike</Button>
          )}
          <Button
            icon={<IoArrowForward />}
            primary
            loading={props.loading}
            onClick={props.isAdding ? onAddSingle : onSubmit}
          >
            {props.isEditing ? 'Save' : props.isAdding ? 'Add this bike' : 'Continue'}
          </Button>
        </ButtonContainer>
      </PageSection>
    </FormContainer>
  );
};
