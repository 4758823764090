import * as React from 'react';

import { JewelryProduct, Product, ValidationError, ProductType } from '@oysterjs/types';
import { TextInput, TextAreaInput } from '@oysterjs/ui/Form/text';
import { PageSection } from '@oysterjs/ui/Page';
import { Button } from '@oysterjs/ui/Button';
import { IoArrowForward } from 'react-icons/io5';
import { FormColumn, FormRow, FormRowHeader } from '@oysterjs/ui/Form/builder';
import { FormContainer } from '@oysterjs/ui/Form/builder';
import {
  RequirementCheckboxContainer,
  RequirementContentContainer,
  RequirementDescription,
  RequirementItemContainer,
  RequirementTitle
} from '@oysterjs/ui/common';
import { Checkbox } from '@oysterjs/ui/Form/checkbox';
import { Tooltip } from '@oysterjs/ui/Tooltip';
import { AttachmentUploader } from '@oysterjs/ui/Attachment';
import { isMerchantReferral } from '@oysterjs/core/analytics/attribution';
import styled from 'styled-components';

// Any value above this requires a purchase receipt.
// "0" means all items require a purchase receipt.
// See https://app.asana.com/0/1200762684494204/1204432446629813
const JEWELRY_VALUE_RECEIPT_REQUIRED = 0;

const TooltipContent = styled.div`
  ul li {
    margin-bottom: 5px;
    font-size: 1.1em;
  }
`;

export interface JewelryFormData {
  ZipCode?: string;
  Name?: string;
  Description?: string;
  Price: string;
  Details: JewelryProduct;
}

const isReceiptRequired = (price: number): boolean => {
  return price >= JEWELRY_VALUE_RECEIPT_REQUIRED && !isMerchantReferral();
};

export const CollectJewelryInfo: React.FunctionComponent<{
  loading?: boolean;
  validationError?: ValidationError;
  formData: JewelryFormData;
  setData: (field: string, fn: (data: JewelryFormData) => JewelryFormData) => void;
  onContinue: (product: Product) => void;
}> = (props) => {
  const [validationError, setValidationError] = React.useState<ValidationError>();

  React.useEffect(() => {
    setValidationError(props.validationError);
  }, [JSON.stringify(props.validationError)]);

  const setData = (field: string, fn: (data: JewelryFormData) => JewelryFormData) => {
    if (validationError?.Field === field) {
      setValidationError(undefined);
    }
    props.setData(field, fn);
  };

  const validate = (formData: JewelryFormData): ValidationError | null => {
    if (!formData.Name) {
      return {
        Field: 'Name',
        Message: 'Please enter a name.'
      };
    }
    if (!formData.Description) {
      return {
        Field: 'Description',
        Message: 'Please enter a description.'
      };
    }
    if (!/^\$?[0-9]+(?:\.[0-9]+)?$/.test(formData.Price)) {
      return {
        Field: 'Price',
        Message: 'Please enter a valid price.'
      };
    }

    const price = parseFloat(formData.Price.replace(/[^.0-9]/, ''));
    const fileLength =
      (formData.Details?.AppraisalFileIDs?.length || 0) +
      (formData.Details?._AppraisalFiles?.length || 0);
    if (isReceiptRequired(price) && fileLength === 0) {
      return {
        Field: 'Attachment',
        Message:
          'Please provide purchase receipt(s), invoice(s) or appraisal document(s) for this jewelry.'
      };
    }
    return null;
  };

  return (
    <FormContainer>
      <FormRowHeader
        title="What are you insuring?"
        description="Enter a recognizable name for the jewelry you're insuring, e.g. Engagement Ring or 18K White Gold Bracelet."
      />
      <FormRow>
        <FormColumn>
          <TextInput
            error={validationError?.Field === 'Name' && validationError?.Message}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData('Name', (prev) => ({ ...prev, Name: value }));
            }}
            value={props.formData.Name}
          />
        </FormColumn>
      </FormRow>
      <FormRowHeader
        title="Describe this jewelry"
        description="Enter a brief description of what the jewelry looks like and what it's made of. If applicable, specify the type of jewelry, precious metal, brand, and stone description (weight, carat, color, and clarity)."
      />
      <FormRow>
        <FormColumn>
          <TextAreaInput
            error={validationError?.Field === 'Description' && validationError?.Message}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData('Description', (prev) => ({ ...prev, Description: value }));
            }}
            value={props.formData.Description}
            style={{ resize: 'none', width: '100%' }}
            rows={5}
          />
        </FormColumn>
      </FormRow>
      <FormRowHeader
        title="How much is this jewelry worth?"
        description="Enter the value of this jewelry if you've had it appraised, or its original purchase price (including taxes) otherwise."
      />
      <FormRow>
        <FormColumn>
          <TextInput
            currency={true}
            style={{ maxWidth: '200px' }}
            error={validationError?.Field === 'Price' && validationError?.Message}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData('Price', (prev) => ({ ...prev, Price: value }));
            }}
            value={props.formData.Price}
            inputMode="decimal"
          />
        </FormColumn>
      </FormRow>
      {isReceiptRequired(Number(props.formData.Price)) && (
        <FormRow>
          <AttachmentUploader
            attachments={
              props.formData.Details?.AppraisalFileIDs?.map((fileId, index) => ({
                ID: fileId,
                Name: `Attachment #${index + 1}`,
                Type: '',
                Size: 0
              })) || []
            }
            title="Supporting Documents"
            description={`To help us speed up the processing time of your policy, please upload the purchase receipt and/or appraisal documents for this jewelry.`}
            multiple={false}
            allowDeleteExisting={false}
            onAttachmentRemoved={(index, _, file) => {
              if (file) {
                if (index >= 0) {
                  setData('Attachment', (prev) => {
                    const updatedFiles = prev.Details._AppraisalFiles;
                    updatedFiles?.splice(index, 1);

                    return {
                      ...prev,
                      Details: {
                        ...prev.Details,
                        _AppraisalFiles: updatedFiles
                      }
                    };
                  });
                }
                return Promise.resolve();
              } else {
                return Promise.resolve();
              }
            }}
            onAttachmentsAdded={(files) => {
              setData('Attachment', (prev) => ({
                ...prev,
                Details: {
                  ...prev.Details,
                  _AppraisalFiles: files
                }
              }));
              return Promise.resolve();
            }}
            validationError={
              validationError?.Field === 'Attachment' && validationError?.Message
                ? validationError
                : undefined
            }
          >
            <Tooltip title="Tips for uploading your documents:">
              <TooltipContent>
                <ul>
                  <li>
                    Ensure the name and address on your uploaded documents match the name and
                    address you input for this application.
                  </li>
                  <li>Provide documents that are clearly legible.</li>
                  <li>Provide documents from the last 3 years.</li>
                  <li>The maximum total file size allowed is 20 MB.</li>
                </ul>
                <p style={{ fontSize: '1.1em', fontWeight: 500, color: '#000000' }}>
                  For appraisal documents:
                </p>
                <ul>
                  <li>Handwritten appraisals are not recommended.</li>
                  <li>Include the appraiser's signature, name and qualifications.</li>
                </ul>
              </TooltipContent>
            </Tooltip>
          </AttachmentUploader>
        </FormRow>
      )}
      <FormRowHeader title="Select any statements that apply" />
      <FormRow>
        <FormColumn>
          <RequirementItemContainer
            style={{ padding: '0px', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              setData('IsNewPurchase', (prev) => ({
                ...prev,
                Details: { ...prev.Details, IsNewPurchase: !prev.Details.IsNewPurchase }
              }));
            }}
          >
            <RequirementCheckboxContainer>
              <Checkbox
                label="IsNewPurchase"
                checked={!!props.formData.Details.IsNewPurchase}
                onChange={() =>
                  setData('IsNewPurchase', (prev) => ({
                    ...prev,
                    Details: { ...prev.Details, IsNewPurchase: !prev.Details.IsNewPurchase }
                  }))
                }
              />
            </RequirementCheckboxContainer>
            <RequirementContentContainer>
              <RequirementTitle>Is this jewelry a new purchase?</RequirementTitle>
              <RequirementDescription>
                Check this box if the jewelry was newly purchased.
              </RequirementDescription>
            </RequirementContentContainer>
          </RequirementItemContainer>
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn>
          <RequirementItemContainer
            style={{ padding: '0px', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              setData('InSafe', (prev) => ({
                ...prev,
                Details: { ...prev.Details, InSafe: !prev.Details.InSafe }
              }));
            }}
          >
            <RequirementCheckboxContainer>
              <Checkbox
                label="InSafe"
                checked={!!props.formData.Details.InSafe}
                onChange={() =>
                  setData('InSafe', (prev) => ({
                    ...prev,
                    Details: { ...prev.Details, InSafe: !prev.Details.InSafe }
                  }))
                }
              />
            </RequirementCheckboxContainer>
            <RequirementContentContainer>
              <RequirementTitle>Is this jewelry going to be stored in a safe?</RequirementTitle>
              <RequirementDescription>
                Check this box if the jewelry is going to be stored inside a safe.
              </RequirementDescription>
            </RequirementContentContainer>
          </RequirementItemContainer>
        </FormColumn>
      </FormRow>
      <PageSection noBorder centered>
        <Button
          icon={<IoArrowForward />}
          primary
          loading={props.loading}
          onClick={(e) => {
            e.preventDefault();

            const err = validate(props.formData);
            if (err) {
              setValidationError(err);
              return;
            }

            props.onContinue({
              Type: ProductType.jewelry,
              SKU: '',
              Name: props.formData.Name || '',
              Description: props.formData.Description || '',
              ImageURL: '',
              Quantity: 1,
              Price: {
                Amount: parseFloat(props.formData.Price.replace(/[^.0-9]/, '')),
                Currency: 'usd'
              },
              Details: props.formData.Details
            });
          }}
        >
          Continue
        </Button>
      </PageSection>
    </FormContainer>
  );
};
