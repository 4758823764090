import * as React from 'react';
import {
  Switch,
  Route as BrowserRoute,
  Redirect,
  useRouteMatch,
  useHistory,
  matchPath
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
const Route = Sentry.withSentryRouting(BrowserRoute);
import { Progress } from '@oysterjs/ui/Form/progress';

import { OysterLogo } from '@oysterjs/ui/Logo';
import { PageContainer, PageSection } from '@oysterjs/ui/Page';
import {
  MarkelMotorCriteria,
  MarkelPersonalCharacteristicFlag,
  MotorProductValidationKeys,
  Product,
  ProductType,
  ValidationError
} from '@oysterjs/types';
import { TextInput } from '@oysterjs/ui/Form/text';
import { Button, ButtonContainer, LinkComponent } from '@oysterjs/ui/Button';
import {
  IoArrowBack,
  IoArrowForward,
  IoBagCheckOutline,
  IoBicycleOutline,
  IoDocumentTextOutline,
  IoPersonOutline
} from 'react-icons/io5';
import { Select } from '@oysterjs/ui/Form/select';
import {
  sdkCreatePolicy,
  sdkGetPaymentSession,
  sdkUpdatePolicy,
  sdkValidatePolicy
} from '@oysterjs/core/api/sdk';
import { ExistingUserWrapper, GetPolicyWrapper, Page, PageProps, PageWrapper } from '../page';
import { getPaymentPage } from '../payment';
import { FormColumn, FormContainer, FormRow, FormRowHeader } from '@oysterjs/ui/Form/builder';
import { AddressInputForm } from '@oysterjs/ui/Form/address';
import {
  RequirementCheckboxContainer,
  RequirementContentContainer,
  RequirementDescription,
  RequirementItemContainer,
  RequirementTitle
} from '@oysterjs/ui/common';
import { Checkbox } from '@oysterjs/ui/Form/checkbox';
import { DateOfBirthInput } from '@oysterjs/ui/Form/dob';
import { getCoveragePage } from '../coverage';
import { useUrlData } from '@oysterjs/core/url';
import { MotorFormData, CollectMotorInfo } from './collect';
import apm from '@oysterjs/core/apm';
import { Policy, MotorProduct } from '@oysterjs/types';
import { ProductInfoPage } from '../product';
import { getProductDisplayName } from '@oysterjs/core/policy';
import { useReferralChannel } from '@oysterjs/core/analytics/attribution';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';
import { userGetPaymentSession } from '@oysterjs/core/api/user';

const CollectOffroadInfoPage: React.FunctionComponent<
  React.PropsWithChildren<{ policy?: Policy }>
> = (props) => {
  const urlData = useUrlData<MotorFormData>();
  const history = useHistory();
  const referralChannel = useReferralChannel();

  const productType = history.location.pathname.split('/')[2] as ProductType;
  const productDisplayName = getProductDisplayName(productType);

  const onSubmit = (product: Product) => {
    if (props.policy) {
      return sdkUpdatePolicy(
        {
          ...props.policy,
          InsuredItems: [product]
        },
        true
      );
    }
    return sdkCreatePolicy([product], { integrationID: urlData.integrationID, referralChannel });
  };

  const onContinue = (policy?: Policy) => {
    if (!props.policy) {
      history.replace(`/app/${productType}/${policy?.ID}`);
    }
    history.push(`/app/${productType}/${props.policy?.ID || policy?.ID}/criteria`);
  };

  const price = props.policy?.InsuredItems?.[0].Price?.Amount;

  if (urlData?.appData && !urlData.appData.Details) {
    urlData.appData.Details = {} as MotorProduct;
  }

  const initialFormData = props.policy
    ? {
        ProductType: productType,
        Price: price?.toString() || '',
        Details: (props.policy.InsuredItems[0].Details || {}) as MotorProduct
      }
    : urlData?.appData
      ? urlData.appData
      : { ProductType: productType, Price: '', Details: {} as MotorProduct };

  return (
    <ProductInfoPage
      title={`Tell us about your ${productDisplayName}`}
      description={`We need a bit of information about your ${productDisplayName} before we can show you coverage options.`}
      initialFormData={initialFormData}
      onSubmit={onSubmit}
      hasError={pages[0].hasError}
      onContinue={onContinue}
      component={CollectMotorInfo}
    />
  );
};

export const CriteriaPage: React.FunctionComponent<React.PropsWithChildren<PageProps>> = (
  props
) => (
  <PageSection>
    <h1>A bit about you</h1>
    <p>Tell us a little bit about yourself and how you use your vehicle.</p>
    <OffroadCriteriaPage {...props} />
  </PageSection>
);

const OffroadCriteriaPage: React.FunctionComponent<React.PropsWithChildren<PageProps>> = (
  props
) => {
  const [inputChanged, setInputChanged] = React.useState(false);
  const criteria = props.policy.Underwriting.Criteria as MarkelMotorCriteria;
  const product = props.policy.InsuredItems[0].Details as MotorProduct;

  React.useEffect(() => {
    if (!props.loading) {
      setInputChanged(false);
    }
  }, [props.loading]);

  const onChange = <T,>(k: string, v: T) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.policy,
      Underwriting: {
        ...props.policy.Underwriting,
        Criteria: {
          ...criteria,
          [k]: v
        }
      }
    });
  };

  const onChangeProduct = <T,>(k: string, v: T) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.policy,
      InsuredItems: props.policy.InsuredItems.map((item) => ({
        ...item,
        Details: {
          ...item.Details,
          [k]: v
        }
      }))
    });
  };

  const onChangeMultiInsured = <T,>(vals: [string, T][]) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.policy,
      Underwriting: {
        ...props.policy.Underwriting,
        Insured: {
          ...props.policy.Underwriting.Insured,
          ...Object.fromEntries(vals)
        }
      }
    });
  };

  const onChangeInsuredDateOfBirth = (day: string, month: string, year: string) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.policy,
      Underwriting: {
        ...props.policy.Underwriting,
        Insured: {
          ...props.policy.Underwriting.Insured,
          DateOfBirth: {
            Day: day,
            Month: month,
            Year: year
          }
        }
      }
    });
  };

  return (
    <FormContainer
      onSubmit={(e) => {
        e.preventDefault();
        props.onNext();
      }}
    >
      <FormRowHeader title="Personal information" />
      <FormRow>
        <FormColumn title="First name">
          <TextInput
            error={
              !inputChanged &&
              props.validationError?.SubField === 'FirstName' &&
              props.validationError?.Message
            }
            value={props.policy.Underwriting.Insured.FirstName}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeMultiInsured([['FirstName', value]]);
            }}
            autoComplete="given-name"
          />
        </FormColumn>
        <FormColumn title="Last name">
          <TextInput
            error={
              !inputChanged &&
              props.validationError?.SubField === 'LastName' &&
              props.validationError?.Message
            }
            value={props.policy.Underwriting.Insured.LastName}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeMultiInsured([['LastName', value]]);
            }}
            autoComplete="family-name"
          />
        </FormColumn>
      </FormRow>
      <FormRow breakMobile>
        <FormColumn title="Email address">
          <TextInput
            style={{ maxWidth: '100%' }}
            error={
              !inputChanged &&
              props.validationError?.SubField === 'Email' &&
              props.validationError?.Message
            }
            value={props.policy.Underwriting.Insured.Email}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeMultiInsured([['Email', value]]);
            }}
            inputMode="email"
            autoComplete="email"
          />
        </FormColumn>
        <FormColumn title="Phone number">
          <TextInput
            style={{ maxWidth: '100%' }}
            error={
              !inputChanged &&
              props.validationError?.SubField === 'Phone' &&
              props.validationError?.Message
            }
            value={props.policy.Underwriting.Insured.Phone}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeMultiInsured([['Phone', value]]);
            }}
            inputMode="tel"
            autoComplete="tel"
          />
        </FormColumn>
      </FormRow>
      <FormRow breakMobile>
        <FormColumn title="Gender identity">
          <Select
            options={[
              { value: '' },
              { displayValue: 'Male', value: 'male' },
              { displayValue: 'Female', value: 'female' },
              { displayValue: 'Non-binary', value: 'non_binary' },
              { displayValue: 'Other', value: 'other' }
            ]}
            error={
              !inputChanged &&
              props.validationError?.SubField === 'Gender' &&
              props.validationError?.Message
            }
            value={props.policy.Underwriting.Insured.Gender}
            onChange={(value) => onChangeMultiInsured([['Gender', value]])}
          />
        </FormColumn>
        <FormColumn title="Marital status">
          <Select
            options={[
              { value: '' },
              { displayValue: 'Single', value: 'single' },
              { displayValue: 'Married', value: 'married' },
              { displayValue: 'Divorced', value: 'divorced' },
              { displayValue: 'Separated', value: 'separated' },
              { displayValue: 'Widowed', value: 'widowed' },
              { displayValue: 'Civil Union', value: 'civil_union' },
              { displayValue: 'Domestic Partnership', value: 'domestic_partnership' }
            ]}
            error={
              !inputChanged &&
              props.validationError?.SubField === 'MaritalStatus' &&
              props.validationError?.Message
            }
            value={props.policy.Underwriting.Insured.MaritalStatus}
            onChange={(value) => onChangeMultiInsured([['MaritalStatus', value]])}
          />
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn title="Date of birth">
          <DateOfBirthInput
            error={
              !inputChanged &&
              props.validationError?.SubField === 'DateOfBirth' &&
              props.validationError?.Message
            }
            initialValue={[
              props.policy.Underwriting.Insured.DateOfBirth?.Day || '',
              props.policy.Underwriting.Insured.DateOfBirth?.Month || '',
              props.policy.Underwriting.Insured.DateOfBirth?.Year || ''
            ]}
            onChange={onChangeInsuredDateOfBirth}
          />
        </FormColumn>
      </FormRow>
      <FormRowHeader title="Residential address" />
      <AddressInputForm
        showSecondLine
        singleSecondLine
        onChange={(address) => {
          onChangeMultiInsured([
            ['AddressLine1', address.streetAddress],
            ['AddressLine2', address.streetAddressLine2],
            ['AddressCity', address.city],
            ['AddressState', address.state],
            ['AddressZipCode', address.zipCode]
          ]);
        }}
        initialValue={{
          streetAddress: props.policy.Underwriting.Insured.AddressLine1,
          streetAddressLine2: props.policy.Underwriting.Insured.AddressLine2,
          city: props.policy.Underwriting.Insured.AddressCity,
          state: props.policy.Underwriting.Insured.AddressState,
          zipCode: props.policy.Underwriting.Insured.AddressZipCode
        }}
        validationError={{
          streetAddress:
            !inputChanged &&
            props.validationError?.SubField === 'AddressLine1' &&
            props.validationError?.Message,
          streetAddressLine2:
            !inputChanged &&
            props.validationError?.SubField === 'AddressLine2' &&
            props.validationError?.Message,
          city:
            !inputChanged &&
            props.validationError?.SubField === 'AddressCity' &&
            props.validationError?.Message,
          state:
            !inputChanged &&
            props.validationError?.SubField === 'AddressState' &&
            props.validationError?.Message,
          zipCode:
            !inputChanged &&
            props.validationError?.SubField === 'AddressZipCode' &&
            props.validationError?.Message
        }}
      />
      <FormRowHeader
        title="How many years of experience do you have?"
        description="Experienced ATV operators can get significant discounts on their insurance."
      />
      <FormRow>
        <FormColumn title="Years of Experience">
          <TextInput
            style={{ maxWidth: '200px' }}
            error={
              !inputChanged &&
              props.validationError?.Field === 'YearsOfExperience' &&
              props.validationError?.Message
            }
            value={criteria.YearsOfExperience}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChange('YearsOfExperience', value);
            }}
            inputMode="email"
            autoComplete="email"
          />
        </FormColumn>
      </FormRow>
      <FormRowHeader
        title="Approximately how many miles will you drive annually?"
        description="Knowing how much you drive helps us give you a better price."
      />
      <FormRow>
        <FormColumn title="Estimated annual miles">
          <TextInput
            style={{ maxWidth: '200px' }}
            error={
              !inputChanged &&
              props.validationError?.Field === 'EstimatedAnnualMileage' &&
              props.validationError?.Message
            }
            value={product.EstimatedAnnualMileage}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeProduct('EstimatedAnnualMileage', value);
            }}
            inputMode="numeric"
          />
        </FormColumn>
      </FormRow>
      <FormRowHeader
        title="Violations and At-Fault Accidents."
        description="How many minor, major, and at-fault violations or accidents have you had in the past three years?"
      />
      <FormRow>
        <FormColumn title="Minor Violations">
          <TextInput
            style={{ maxWidth: '200px' }}
            error={
              !inputChanged &&
              props.validationError?.Field === 'MinorViolations' &&
              props.validationError?.Message
            }
            value={criteria.MinorViolations}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChange('MinorViolations', value);
            }}
            inputMode="numeric"
          />
        </FormColumn>
        <FormColumn title="Major Violations">
          <TextInput
            style={{ maxWidth: '200px' }}
            error={
              !inputChanged &&
              props.validationError?.Field === 'MajorViolations' &&
              props.validationError?.Message
            }
            value={criteria.MajorViolations}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChange('MajorViolations', value);
            }}
            inputMode="numeric"
          />
        </FormColumn>
        <FormColumn title="At-Fault Accidents">
          <TextInput
            style={{ maxWidth: '200px' }}
            error={
              !inputChanged &&
              props.validationError?.Field === 'AtFaultAccidents' &&
              props.validationError?.Message
            }
            value={criteria.AtFaultAccidents}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChange('AtFaultAccidents', value);
            }}
            inputMode="numeric"
          />
        </FormColumn>
      </FormRow>
      <FormRowHeader
        title="Driver's License."
        description="You must have a driver's license to insure this vehicle. Please enter your license's state and number."
      />
      <FormRow>
        <FormColumn title="License State">
          <TextInput
            error={
              !inputChanged &&
              props.validationError?.Field === 'DriversLicenseState' &&
              props.validationError?.Message
            }
            value={criteria.DriversLicenseState}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChange('DriversLicenseState', value);
            }}
          />
        </FormColumn>
        <FormColumn title="License Number">
          <TextInput
            error={
              !inputChanged &&
              props.validationError?.Field === 'DriversLicenseNumber' &&
              props.validationError?.Message
            }
            value={criteria.DriversLicenseNumber}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChange('DriversLicenseNumber', value);
            }}
          />
        </FormColumn>
      </FormRow>
      <FormRowHeader
        title="Select any statements that apply."
        description="Select the items below that most accurately describe you."
      />
      <FormRow
        error={
          !inputChanged && props.validationError?.Field === 'PersonalCharacteristicFlags'
            ? props.validationError?.Message
            : undefined
        }
      >
        <FormColumn>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {[
              {
                value: MarkelPersonalCharacteristicFlag.taken_safety_course,
                name: 'I have taken an ATV safety course.',
                description: 'You have taken a basic, accredited ATV safety course.',
                hidden: false,
                indented: false
              },
              {
                value: MarkelPersonalCharacteristicFlag.motorcycle_endorsed,
                name: "My driver's license has a motorcycle endorsement.",
                description:
                  "Your driver's license has an added note or credential legally allowing you to ride a motorcycle on public roads.",
                hidden: false,
                indented: false
              },
              {
                value: MarkelPersonalCharacteristicFlag.homeowner,
                name: 'I am a homeowner.',
                description: 'You are a homeowner or a titled owner on a home your spouse owns.',
                hidden: false,
                indented: false
              }
            ]
              .filter((option) => !option.hidden)
              .map((option) => (
                <RequirementItemContainer
                  style={{
                    padding: '0px',
                    cursor: 'pointer',
                    display: 'border-box',
                    paddingLeft: option.indented ? '16px' : undefined
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    const s = new Set(criteria.PersonalCharacteristicFlags);
                    if (s.has(option.value)) {
                      s.delete(option.value);
                    } else {
                      s.add(option.value);
                    }
                    onChange('PersonalCharacteristicFlags', [...s]);
                  }}
                  key={option.value}
                >
                  <RequirementCheckboxContainer>
                    <Checkbox
                      label={option.name}
                      checked={criteria.PersonalCharacteristicFlags.includes(option.value)}
                      onChange={() => {
                        const s = new Set(criteria.PersonalCharacteristicFlags);
                        if (s.has(option.value)) {
                          s.delete(option.value);
                        } else {
                          s.add(option.value);
                        }
                        onChange('PersonalCharacteristicFlags', [...s]);
                      }}
                    />
                  </RequirementCheckboxContainer>
                  <RequirementContentContainer>
                    <RequirementTitle>{option.name}</RequirementTitle>
                    <RequirementDescription>{option.description}</RequirementDescription>
                  </RequirementContentContainer>
                </RequirementItemContainer>
              ))}
          </div>
        </FormColumn>
      </FormRow>
      <PageSection noBorder noPadding centered>
        <ButtonContainer center>
          {props.onBack && (
            <Button leftIcon={<IoArrowBack />} onClick={props.onBack}>
              Back
            </Button>
          )}
          <Button
            loading={props.loading}
            onClick={(e) => {
              e.preventDefault();
              props.onNext();
            }}
            icon={<IoArrowForward />}
            primary
          >
            Continue
          </Button>
        </ButtonContainer>
      </PageSection>
    </FormContainer>
  );
};

const pages: Array<Page> = [
  {
    path: '/app/$PRODUCT_TYPE/:id',
    render: (props) => (
      <GetPolicyWrapper policyId={props.match.params.id}>
        {(policy) => <CollectOffroadInfoPage policy={policy} />}
      </GetPolicyWrapper>
    ),
    hasError: (validationError: ValidationError) =>
      MotorProductValidationKeys.indexOf(validationError.Field as keyof MotorProduct) > -1,
    icon: <IoBicycleOutline />
  },
  {
    path: '/app/$PRODUCT_TYPE/:id/criteria',
    render: (props) => (
      <GetPolicyWrapper policyId={props.match.params.id}>
        {(policy) => <PageWrapper pages={pages} policy={policy} component={CriteriaPage} />}
      </GetPolicyWrapper>
    ),
    hasError: () => true,
    icon: <IoPersonOutline />
  },
  {
    path: '/app/$PRODUCT_TYPE/:id/coverage',
    render: (props) => (
      <GetPolicyWrapper policyId={props.match.params.id}>
        {(policy) => (
          <PageWrapper
            policy={policy}
            pages={pages}
            component={getCoveragePage(
              'Review your coverage',
              'Oyster coverage is flexible and comprehensive by default. You can also add additional coverages to your policy.',
              () => sdkValidatePolicy(policy.ID)
            )}
          />
        )}
      </GetPolicyWrapper>
    ),
    hasError: () => false,
    icon: <IoDocumentTextOutline />
  },
  {
    path: '/app/$PRODUCT_TYPE/:id/payment',
    render: (props) => (
      <ExistingUserWrapper>
        {(account) => (
          <GetPolicyWrapper policyId={props.match.params.id}>
            {(policy) => (
              <PageWrapper
                policy={policy}
                pages={pages}
                component={getPaymentPage(
                  () =>
                    account ? userGetPaymentSession(policy.ID) : sdkGetPaymentSession(policy.ID),
                  window.location.origin + `/policy/${policy.ID}/complete?flow=atv`,
                  account
                )}
              />
            )}
          </GetPolicyWrapper>
        )}
      </ExistingUserWrapper>
    ),
    hasError: (validationError: ValidationError) => validationError.Field === 'Insured',
    icon: <IoBagCheckOutline />
  }
];

export default (): JSX.Element => {
  // Side effect to trigger re-renders when the page changes
  useRouteMatch();
  const history = useHistory();
  const productType = history.location.pathname.split('/')[2] as ProductType;

  const current = pages.findIndex((page) =>
    matchPath(window.location.pathname, {
      path: page.path.replace('$PRODUCT_TYPE', productType),
      exact: true
    })
  );

  React.useEffect(() => {
    apm().sendEvent('oyster.d2c.flow_page_change', {
      flow_type: productType,
      page_index: current,
      route: pages[current]?.path.replace('$PRODUCT_TYPE', productType),
      path: window.location.pathname,
      query: window.location.search
    });
  }, [current]);

  return (
    <PageContainer width={700}>
      <PageSection centered>
        <OysterLogo scale={1.5} inline />
      </PageSection>
      <ErrorBoundary forceMobile>
        {current >= 0 && (
          <PageSection noBorder noPadding>
            <Progress
              steps={pages.map((page, i) =>
                i < current ? (
                  <LinkComponent
                    href={(() => {
                      const match = /\/(pol_.+)\//.exec(history.location.pathname);
                      if (match && match[1]) {
                        return pages[i].path
                          .replace(':id', match[1])
                          .replace('$PRODUCT_TYPE', productType);
                      }
                      return pages[i].path;
                    })()}
                  >
                    {page.icon}
                  </LinkComponent>
                ) : (
                  page.icon
                )
              )}
              currentStep={current}
            />
          </PageSection>
        )}
        <Switch>
          <Route
            exact
            path={`/app/${productType}/ineligible`}
            render={() => (
              <PageSection>
                <h1>Sorry!</h1>
                <p>
                  {JSON.parse(window.localStorage.getItem('underwriting_error') || '{}').Message ||
                    null}
                </p>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <img width={260} height={254} src="/images/underwriting_error.svg" alt="" />
                </div>
              </PageSection>
            )}
          ></Route>
          <Route exact path={`/app/${productType}`} render={() => <CollectOffroadInfoPage />} />
          {pages.map((page) => (
            <Route
              exact
              key={page.path}
              path={page.path.replace('$PRODUCT_TYPE', productType)}
              render={page.render}
            />
          ))}
          <Redirect to={`/app/${productType}`} />
        </Switch>
      </ErrorBoundary>
    </PageContainer>
  );
};
