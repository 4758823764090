import styled from 'styled-components';

import { OysterLogo } from '@oysterjs/ui/Logo';
import { PageContainer, PageSection } from '@oysterjs/ui/Page';
import { ProductType } from '@oysterjs/types';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';

const ProductsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
`;

const ProductContainer = styled.div`
  border-radius: 20px;
  padding: 20px;
  border: 2px dotted #f2f2f2;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
`;

const ProductImage = styled.img`
  display: block;
  height: 120px;
  width: 120px;
`;

const ProductLabel = styled.div`
  font-weight: 500;
  font-size: 1em;
  width: 100%;
  text-align: center;
  padding: 10px 0px;
`;

const Product = (props: { name: string; image: string }) => (
  <div>
    <ProductContainer>
      <ProductImage src={props.image} height={200} width={200} />
    </ProductContainer>
    <ProductLabel>{props.name}</ProductLabel>
  </div>
);

const ProductSelectionPage = (props: {
  onContinue: (selectedProductType: ProductType) => void;
}): JSX.Element => {
  const products = [
    {
      name: 'ATV',
      type: ProductType.offroad,
      src: 'https://dashboard.withoyster.com/images/atv_insurance.svg',
      hideD2C: true
    },
    {
      name: 'Motorcycle',
      type: ProductType.motorcycle,
      src: 'https://dashboard.withoyster.com/images/motorcycle_insurance.svg',
      hideD2C: true
    },
    {
      name: 'Bike/eBike',
      type: ProductType.bike,
      src: 'https://dashboard.withoyster.com/images/bike_insurance.svg'
    },
    {
      name: 'Jewelry',
      type: ProductType.jewelry,
      src: 'https://dashboard.withoyster.com/images/jewelry_insurance.svg'
    },
    {
      name: 'Phone',
      type: ProductType.phone,
      src: 'https://dashboard.withoyster.com/images/phone_insurance.svg'
    },
    {
      name: 'Electronics',
      type: ProductType.electronics,
      src: 'https://dashboard.withoyster.com/images/electronics_insurance.svg'
    }
  ];

  return (
    <>
      <PageSection noBorder>
        <h1>What do you want to insure?</h1>
        <ProductsContainer>
          {products
            .filter((product) => !product.hideD2C)
            .map((product) => (
              <div
                key={product.name}
                onClick={() => {
                  props.onContinue(product.type);
                }}
              >
                <Product name={product.name} image={product.src} />
              </div>
            ))}
        </ProductsContainer>
      </PageSection>
    </>
  );
};

export default (props: { onContinue: (selectedProductType: ProductType) => void }): JSX.Element => (
  <PageContainer width={700}>
    <PageSection centered>
      <OysterLogo scale={1.5} inline />
    </PageSection>
    <ErrorBoundary forceMobile>
      <ProductSelectionPage {...props} />
    </ErrorBoundary>
  </PageContainer>
);
